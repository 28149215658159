import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setSelectedRouteTitle } from "../controllers/control_bar";
import { readContact, updateContact } from "../controllers/crm";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";

import {
  locales,
  crmLeadStatuses,
  crmLeadStatusImages,
} from "../utils/constants";
import toast from "react-hot-toast";

const locale = localStorage.getItem("locale") ?? locales.italian;

function SLeadStatus() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const contactID = searchParams.get("contact_id");

  const contact = useSelector((state) => state.crm.contact);

  const [status, setStatus] = useState(null);

  useEffect(() => {
    _init();
  }, []);

  useEffect(() => {
    setStatus(contact?.status);
  }, [contact]);

  const _init = async () => {
    if (!contactID) {
      navigate(-1);
      return;
    }

    dispatch(setSelectedRouteTitle("crm"));
    dispatch(
      readContact({
        id: contactID,
      })
    );
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%]">
          <div className="flex flex-row items-center justify-between w-[100%]">
            <p className="text-[20px] font-[600]">{t("lead_status")}</p>
          </div>
          <div className="h-[20px]" />
          {!contact ? (
            <CLoader />
          ) : (
            <div className="flex flex-col self-center items-center justify-start w-[100%] h-[100%]">
              <div className="flex flex-col items-start justify-start w-[100%]">
                <p className="text-[1rem]">
                  {`Name: ${contact.firstName ?? "N/A"} ${
                    contact.lastName ?? "N/A"
                  }`}
                </p>
                <p className="text-[0.90rem]">{`Email: ${
                  contact.email ?? "N/A"
                }`}</p>
              </div>
              <div className="my-[2%]" />
              <div className="flex flex-row self-center items-center justify-between w-[100%] h-[30%] p-[2%] bg-white shadow-lg border-[#F5F5F5] border-solid border-[1px] cursor-pointer">
                <CStatus
                  icon={crmLeadStatusImages[crmLeadStatuses.positive]}
                  status={crmLeadStatuses.positive}
                  isSelected={status == crmLeadStatuses.positive}
                  onClick={(_) => {
                    setStatus(_);
                  }}
                />
                <CStatus
                  icon={crmLeadStatusImages[crmLeadStatuses.neutral]}
                  status={crmLeadStatuses.neutral}
                  isSelected={status == crmLeadStatuses.neutral}
                  onClick={(_) => {
                    setStatus(_);
                  }}
                />
                <CStatus
                  icon={crmLeadStatusImages[crmLeadStatuses.negative]}
                  status={crmLeadStatuses.negative}
                  isSelected={status == crmLeadStatuses.negative}
                  onClick={(_) => {
                    setStatus(_);
                  }}
                />
              </div>
              <div className="flex-grow" />
              <CButton
                text="Continue"
                onClick={async () => {
                  if (!status) {
                    toast.error("Status must be selected.");
                    return;
                  }

                  const res = await dispatch(
                    updateContact({
                      id: contactID,
                      status,
                    })
                  );

                  if (res.payload) {
                    navigate(-1);
                  }
                }}
              />
              <div className="my-[2%]" />
            </div>
          )}
        </div>
      </div>
    </CContainer>
  );
}

function CStatus({ icon, status, isSelected, onClick }) {
  return (
    <div className="flex flex-col items-center justify-center">
      <img
        className="w-[5rem] h-[5rem] object-contain rounded-[2.5rem]"
        src={icon}
      />
      <div className="my-[1rem]" />
      <input
        type="checkbox"
        checked={isSelected}
        className="checkbox checkbox-lg"
        onClick={() => {
          onClick(status);
        }}
      />
    </div>
  );
}

export default SLeadStatus;
