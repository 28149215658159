import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import { setSelectedRouteTitle } from "../controllers/control_bar";
import {
  createLeadFinancialDocument,
  readContact,
  readLeadFinancialDocuments,
  updateLeadFinancialDocument,
} from "../controllers/crm";
import { setFile, uploadFile } from "../controllers/files";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CInput from "../components/input";

import {
  leadFinancialDocumentTypesBankTransfer,
  leadFinancialDocumentTypesFinancialCompany,
  leadFinancialDocumentTypesFinancialCompanyWithGuarantor,
  filesURLSplitSequence,
  leadFinancialDocumentStatuses,
  leadPaymentTypes,
} from "../utils/constants";
import { getFileNameAndExt } from "../utils/misc";

import AttachmentAddSVG from "../assets/images/attachment_add.svg";
import CancelSVG from "../assets/images/cancel.svg";
import CancelWhiteSVG from "../assets/images/cancel_white.svg";
import CheckSVG from "../assets/images/check.svg";
import CheckWhiteSVG from "../assets/images/check_white.svg";
import CircleWhiteSVG from "../assets/images/circle_white.svg";
import EditSVG from "../assets/images/edit.svg";
import SaveSVG from "../assets/images/save.svg";
import ViewSVG from "../assets/images/view.svg";

function SLeadFinancialDocuments() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const contactID = searchParams.get("contact_id");

  const isLoading = useSelector((state) => state.crm.isLoading);
  const contact = useSelector((state) => state.crm.contact);
  const leadFinancialDocuments = useSelector(
    (state) => state.crm.leadFinancialDocuments
  );

  useEffect(() => {
    _init();
  }, []);

  useEffect(() => {}, [contact]);

  const _init = async () => {
    if (!contactID) {
      navigate(-1);
      return;
    }

    dispatch(setSelectedRouteTitle("crm"));
    dispatch(
      readContact({
        id: contactID,
      })
    );
    dispatch(
      readLeadFinancialDocuments({
        lead_id: contactID,
      })
    );
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%]">
          <div className="flex flex-row items-center justify-between w-[100%]">
            <p className="text-[20px] font-[600]">
              {t("lead_financial_documents")}
            </p>
            <p className="text-[1rem] font-[500]">
              {leadFinancialDocuments.length}/
              {
                Object.keys(
                  contact?.paymentType == leadPaymentTypes.bank_transfer
                    ? leadFinancialDocumentTypesBankTransfer
                    : contact?.paymentType == leadPaymentTypes.financial_company
                    ? leadFinancialDocumentTypesFinancialCompany
                    : contact?.paymentType ==
                      leadPaymentTypes.financial_company_with_guarantor
                    ? leadFinancialDocumentTypesFinancialCompanyWithGuarantor
                    : {}
                ).length
              }
            </p>
          </div>
          <div className="h-[0.5rem]" />
          {!contact ? (
            <CLoader />
          ) : (
            <div className="flex flex-col items-start justify-start w-[100%] h-[100%] p-[2%] overflow-scroll">
              {Object.values(
                contact.paymentType == leadPaymentTypes.bank_transfer
                  ? leadFinancialDocumentTypesBankTransfer
                  : contact.paymentType == leadPaymentTypes.financial_company
                  ? leadFinancialDocumentTypesFinancialCompany
                  : contact.paymentType ==
                    leadPaymentTypes.financial_company_with_guarantor
                  ? leadFinancialDocumentTypesFinancialCompanyWithGuarantor
                  : {}
              ).map((_) => (
                <CLeadFinancialDocument type={_} />
              ))}
            </div>
          )}
        </div>
      </div>
    </CContainer>
  );
}

function CLeadFinancialDocument({ type }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.files.isLoading);
  const file = useSelector((state) => state.files.file);
  const contact = useSelector((state) => state.crm.contact);
  const leadFinancialDocuments = useSelector(
    (state) => state.crm.leadFinancialDocuments
  );

  const leadFinancialDocument = useMemo(
    () => leadFinancialDocuments.find((_) => _.type == type),
    [type, leadFinancialDocuments]
  );

  const [note, setNote] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (leadFinancialDocument) {
      setNote(leadFinancialDocument.note);
      setAttachment(leadFinancialDocument.attachment);
    }
  }, [leadFinancialDocument]);

  return (
    <div className="flex flex-col items-start justify-center w-[100%] p-[2%] mb-[0.5rem] bg-white shadow-sm border-[#F5F5F5] border-solid border-[1px] flex-shrink-0">
      <div className="flex flex-row items-center justify-start w-[100%]">
        <div
          className={`p-[0.3rem] rounded-full ${
            leadFinancialDocument?.status ==
            leadFinancialDocumentStatuses.uploaded
              ? "bg-yellow-500"
              : leadFinancialDocument?.status ==
                leadFinancialDocumentStatuses.approved
              ? "bg-green-500"
              : leadFinancialDocument?.status ==
                leadFinancialDocumentStatuses.rejected
              ? "bg-red-500"
              : "bg-gray-500"
          }`}
        >
          <img
            src={
              leadFinancialDocument?.status ==
              leadFinancialDocumentStatuses.uploaded
                ? CircleWhiteSVG
                : leadFinancialDocument?.status ==
                  leadFinancialDocumentStatuses.approved
                ? CheckWhiteSVG
                : leadFinancialDocument?.status ==
                  leadFinancialDocumentStatuses.rejected
                ? CancelWhiteSVG
                : CircleWhiteSVG
            }
            className="h-[1rem] w-[1rem]"
          />
        </div>
        <div className="mx-[0.5rem]" />
        <p className="text-[1rem] font-[600] w-[70%]">
          {t(type)}
          {[
            leadFinancialDocumentTypesBankTransfer.identity_document,
            leadFinancialDocumentTypesBankTransfer.health_card,
          ].includes(type)
            ? ` ${t("(front_and_back)")}`
            : ""}
        </p>
        <div className="flex-grow" />
        {leadFinancialDocument?.status !=
        leadFinancialDocumentStatuses.approved ? (
          <img
            src={leadFinancialDocument ? (!edit ? EditSVG : SaveSVG) : CheckSVG}
            className="h-[1.5rem] w-[1.5rem] cursor-pointer"
            onClick={async () => {
              let attachmentFileKey;

              if (!attachment) {
                toast.error("Attachment must be selected.");
                return;
              }

              if (typeof attachment == "object") {
                await dispatch(setFile(attachment));

                const _attachment = await dispatch(
                  uploadFile({
                    name: `${contact.firstName ?? "N/A"} ${
                      contact.lastName ?? "N/A"
                    } - ${t(type)} - ${Date.now()}`,
                  })
                );

                attachmentFileKey = _attachment.payload.split(
                  filesURLSplitSequence
                )[1];
              } else {
                attachmentFileKey = attachment.split(filesURLSplitSequence)[1];
              }

              if (!leadFinancialDocument) {
                const res = await dispatch(
                  createLeadFinancialDocument({
                    lead_id: contact.id,
                    type: type,
                    note,
                    attachment: attachmentFileKey,
                  })
                );

                if (res.payload) {
                  dispatch(
                    readLeadFinancialDocuments({
                      lead_id: contact.id,
                    })
                  );
                }
              } else {
                if (!edit) {
                  setEdit(true);
                } else {
                  const res = await dispatch(
                    updateLeadFinancialDocument({
                      lead_id: contact.id,
                      type: type,
                      note,
                      attachment: attachmentFileKey,
                    })
                  );

                  if (res.payload) {
                    dispatch(
                      readLeadFinancialDocuments({
                        lead_id: contact.id,
                      })
                    );
                    setEdit(false);
                  }
                }
              }
            }}
          />
        ) : null}
      </div>
      {isLoading && file == attachment ? (
        <>
          <div className="h-[20px]" />
          <div className="h-[30px] w-[30px]">
            <CLoader />
          </div>
        </>
      ) : null}
      <CInput
        type="text"
        className="mb-[5px] mt-[5px]"
        placeholder={t("enter_note_(optional)")}
        value={note}
        onChange={(e) => setNote(e.target.value)}
        disabled={!leadFinancialDocument ? false : !edit}
      />
      {!attachment ? (
        <>
          <label
            htmlFor={`attachment_${type}`}
            className="flex flex-row items-start justify-start w-[100%] p-[2%] bg-[#C0C0C0] rounded-[10px] cursor-pointer"
          >
            <p className="text-white">{t("add_attachment")}</p>
            <div className="flex-grow" />
            <img src={AttachmentAddSVG} className="h-[1.6rem] w-[1.6rem]" />
          </label>
          <input
            id={`attachment_${type}`}
            accept=".pdf, .doc, .docx, image/*"
            className="hidden"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                setAttachment(file);
              }
            }}
            type="file"
            value={""}
            disabled={!leadFinancialDocument ? false : !edit}
          />
        </>
      ) : (
        <div className="flex flex-row items-start justify-start w-[100%]">
          <p className="w-[80%] overflow-hidden text-ellipsis whitespace-nowrap">
            {typeof attachment == "object"
              ? attachment.name
              : attachment.split(filesURLSplitSequence)[1]}
          </p>
          <div className="flex-grow" />
          {typeof attachment == "string" ? (
            <>
              <img
                src={ViewSVG}
                className="h-[1.6rem] w-[1.6rem] cursor-pointer"
                onClick={() => {
                  window.open(attachment, "__blank");
                }}
              />
              <div className="mx-[0.2rem]" />
            </>
          ) : null}
          {leadFinancialDocument?.status !=
          leadFinancialDocumentStatuses.approved ? (
            <img
              src={CancelSVG}
              className="h-[1.6rem] w-[1.6rem] cursor-pointer"
              onClick={() => {
                if (!(!leadFinancialDocument ? false : !edit)) {
                  setAttachment(null);
                }
              }}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}

export default SLeadFinancialDocuments;
