const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function noValidator() {
  return;
}

function nullValidator(key, value, { allowNull = false } = {}) {
  let error;
  if (!allowNull) {
    if (value == null || value == undefined || value === "") {
      error = `${key} can't be empty.`;
    }
  }
  return error;
}

function emailValidator(key, value, { allowNull = false } = {}) {
  let error = nullValidator(key, value, { allowNull });
  if (error) {
    return error;
  }
  if (!allowNull || !nullValidator(key, value)) {
    const match = value.match(emailRegex);
    if (!match) {
      error = `${key} is not valid.`;
    }
  }
  return error;
}

function lengthValidator(key, value, length, { allowNull = false } = {}) {
  let error = nullValidator(key, value, { allowNull });
  if (error) {
    return error;
  }
  if (!allowNull || !nullValidator(key, value)) {
    const match = value.length >= length;
    if (!match) {
      error = `${key} must be at least ${length} characters.`;
    }
  }
  return error;
}

function numberValidator(key, value, limit, { allowNull = false } = {}) {
  let error = nullValidator(key, value, { allowNull });
  if (error) {
    return error;
  }
  if (!allowNull || !nullValidator(key, value)) {
    const num = Number(value);
    if (isNaN(num) || !Number.isInteger(num)) {
      error = `${key} is not valid.`;
    }
    if (limit) {
      if (num > limit) {
        error = `${key} can't be greater than ${limit}.`;
      }
    }
  }
  return error;
}

function dateValidator(key, value, { allowNull = false } = {}) {
  let error = nullValidator(key, value, { allowNull });
  if (error) {
    return error;
  }
  if (!allowNull || !nullValidator(key, value)) {
    try {
      new Date(value);
    } catch (e) {
      error = `${key} is not valid.`;
    }
  }
  return error;
}

function selectValidator(key, value, { allowNull = false } = {}) {
  let error;
  if (!allowNull || !nullValidator(key, value)) {
    if (value == null || value == undefined || value === "") {
      error = `${key} must be selected.`;
    }
  }
  return error;
}

function validate(validatorClosures) {
  const errors = [];

  for (let i = 0; i < validatorClosures.length; i++) {
    const f = validatorClosures[i];
    const error = f();
    if (error) {
      errors.push(error);
    }
  }

  if (errors.length > 0) {
    return errors[0];
  } else {
    return null;
  }
}

export {
  noValidator,
  nullValidator,
  emailValidator,
  lengthValidator,
  numberValidator,
  dateValidator,
  selectValidator,
  validate,
};
