import { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setSelectedRouteTitle } from "../controllers/control_bar";
import { readContact } from "../controllers/crm";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";
import { CDropDown, CDropDownMenu } from "../components/dropdown";
import CDateTimePicker from "../components/datetimepicker";
import CAudio from "../components/audio";

import NoteSVG from "../assets/images/note.svg";
import EmailSVG from "../assets/images/email.svg";
import OutgoingCallSVG from "../assets/images/outgoing_call.svg";
import IngoingCallSVG from "../assets/images/ingoing_call.svg";
import SMSSVG from "../assets/images/sms.svg";
import WhatsappPNG from "../assets/images/whatsapp.png";
import MessengerPNG from "../assets/images/messenger.png";
import MeetingSVG from "../assets/images/meeting.svg";
import AttachmentAddSVG from "../assets/images/attachment_add.svg";
import CancelSVG from "../assets/images/cancel.svg";
import AttachmentSVG from "../assets/images/attachment.svg";
import DownloadSVG from "../assets/images/download.svg";
import OutgoingCallWhiteSVG from "../assets/images/outgoing_call_white.svg";

import {
  crmActivityTypes,
  dateFormatter,
  dateTimeFormatter,
  filesURLSplitSequence,
  locale,
  locales,
  timeFormatter,
  userRoles,
} from "../utils/constants";
import {
  clearActivities,
  createActivity,
  readActivities,
} from "../controllers/crm";
import {
  downloadFile,
  setFile,
  setFiles,
  uploadFile,
} from "../controllers/files";
import { getFileNameAndExt } from "../utils/misc";

function SActivities() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const location = useLocation();

  const contactID = searchParams.get("contact_id");
  const { type, note, isCallReport, attachment } = location.state ?? {};

  const contact = useSelector((state) => state.crm.contact);

  useEffect(() => {
    _init();
  }, []);

  const _init = async () => {
    if (!contactID) {
      navigate(-1);
      return;
    }

    dispatch(setSelectedRouteTitle("crm"));
    dispatch(
      readContact({
        id: contactID,
      })
    );

    if (type || note || attachment) {
      window.modal_activity.showModal();
    }
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%]">
          <div className="flex flex-row items-center justify-between w-[100%]">
            <p className="text-[20px] font-[600]">{t("activites")}</p>
            <CButton
              className="px-[1rem]"
              text="Add"
              onClick={() => {
                window.modal_activity.showModal();
              }}
            />
          </div>
          <div className="h-[20px]" />
          {!contact ? <CLoader /> : <CContactActivities />}
        </div>
      </div>
      <MActivity
        typeLocation={type}
        noteLocation={note}
        isCallReportLocation={isCallReport}
        attachmentLocation={attachment}
      />
    </CContainer>
  );
}

const CContactActivities = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.crm.isLoading);
  const contact = useSelector((state) => state.crm.contact);
  const pagesInfo = useSelector((state) => state.crm.activitiesPagesInfo);
  const activities = useSelector((state) => state.crm.activities);

  const [selectedActivityID, setSelectedActivityID] = useState(null);

  const groupedActivities = useMemo(() => {
    const unsortedActivities = JSON.parse(JSON.stringify(activities));
    unsortedActivities.forEach((_) => {
      _.date = new Date(_.date);
      _.createdAt = new Date(_.createdAt);
      _.updatedAt = new Date(_.updatedAt);
    });
    const _activities = unsortedActivities.sort(
      (a, b) => b.createdAt - a.createdAt
    );
    const groups = {};
    for (let i = 0; i < _activities.length; i++) {
      const activity = _activities[i];
      const date = dateFormatter.format(activity.createdAt);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(activity);
    }
    return groups;
  }, [activities]);

  useEffect(() => {
    dispatch(
      readActivities({
        contact_id: contact.id,
      })
    );

    return () => dispatch(clearActivities());
  }, [contact]);

  return (
    <div className="flex flex-col items-start justify-start p-[2%] w-[100%] h-[100%] overflow-scroll">
      {activities.length == 0 ? (
        <p className="text-[0.9rem]">No activities yet.</p>
      ) : (
        <>
          {Object.keys(groupedActivities).map((date) => (
            <>
              <div className="flex flex-row items-center justify-center w-[100%]">
                <div className="h-[1px] bg-[#D3D3D3] w-[50%]" />
                <p className="text-[0.9rem] font-[600] self-center px-[2rem]">
                  {date == dateFormatter.format(new Date()) ? "Today" : date}
                </p>
                <div className="h-[1px] bg-[#D3D3D3] w-[50%]" />
              </div>
              {groupedActivities[date].map((activity) => (
                <CActivity
                  activity={activity}
                  isOpen={selectedActivityID == activity.id}
                  onClick={(id) => {
                    if (selectedActivityID != id) {
                      setSelectedActivityID(id);
                    } else {
                      setSelectedActivityID(null);
                    }
                  }}
                />
              ))}
            </>
          ))}
          {pagesInfo.current < pagesInfo.total ? (
            <>
              <div className="mt-[0.5rem]" />
              <CButton
                isLoading={isLoading}
                className="w-[50%] self-center"
                text={t("load_more")}
                onClick={async () => {
                  dispatch(
                    readActivities({
                      contact_id: contact.id,
                      page_no: pagesInfo.current + 1,
                    })
                  );
                }}
              />
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

function isScheduledActivity(activity) {
  if (!activity.date) {
    return false;
  }
  return activity.date.getTime() - new Date().getTime() > 60000;
}
const CActivity = ({ activity, isOpen, onClick }) => {
  const dispatch = useDispatch();
  const contentRef = useRef(null);

  const hasAudioAttachment = activity?.attachment?.endsWith(".wav");

  const isLoading = useSelector((state) => state.files.isLoading);
  const files = useSelector((state) => state.files.files);

  return (
    <div
      className={`flex flex-col items-center justify-center w-[100%] p-[2%] my-[0.3rem] bg-white ${
        activity.isCallReport ? "bg-[#F1F1F1]" : "bg-white"
      } shadow-sm border-[#F5F5F5] border-solid border-[1px] cursor-pointer flex-shrink-0 overflow-scroll`}
      onClick={() => {
        onClick(activity.id);
      }}
    >
      <div className="flex flex-col items-center justify-start w-[100%]">
        {activity.isCallReport ? (
          <div className="p-[0.5rem] bg-primary rounded-full self-start">
            <img className="h-[1rem] w-[1rem]" src={OutgoingCallWhiteSVG} />
          </div>
        ) : null}
        <div
          className="flex flex-row items-center justify-start w-[100%]"
          onClick={() => onClick(activity.id)}
        >
          <img className="w-[25px] h-[25px]" src={getActivityIcon(activity)} />
          <div className="px-[0.2rem]" />
          <p className="text-[0.95rem] whitespace-normal">
            {getActivityText(activity)}
          </p>
          <div className="flex-grow" />
          <p className="text-[0.95rem] whitespace-normal self-end">
            {timeFormatter.format(activity.createdAt)}
          </p>
        </div>
        {activity.attachment ? (
          <div className="flex flex-row items-center justify-start w-[100%] mt-[2%] p-[2%] bg-[#F0F0F0] rounded-[10px]">
            {isLoading && files.includes(activity.attachment) ? (
              <div className="h-[2rem] w-[2rem]">
                <CLoader />
              </div>
            ) : (
              <img src={AttachmentSVG} className="h-[1.6rem] w-[1.6rem]" />
            )}
            <div className="px-[0.5rem]" />
            {hasAudioAttachment ? (
              <CAudio url={activity.attachment} />
            ) : (
              <>
                <p className="text-[0.9rem] w-[80%] overflow-hidden text-ellipsis whitespace-nowrap">
                  {activity.attachment.split(filesURLSplitSequence)[1]}
                </p>
                <div className="flex-grow" />
              </>
            )}
            <img
              src={DownloadSVG}
              className="h-[1.6rem] w-[1.6rem]"
              onClick={async (e) => {
                e.stopPropagation();

                await dispatch(
                  setFiles({ file: activity.attachment, status: 1 })
                );
                await dispatch(downloadFile({ file: activity.attachment }));
                await dispatch(
                  setFiles({ file: activity.attachment, status: 0 })
                );
              }}
            />
          </div>
        ) : null}
        {isScheduledActivity(activity) ? (
          <div className="flex flex-row items-center justify-between w-[100%] py-[0.5rem]">
            <p className="text-[0.9rem] font-[600]">Scheduled</p>
            <p className="text-[0.9rem]">
              {dateTimeFormatter.format(activity.date)}
            </p>
          </div>
        ) : null}
      </div>
      <div
        ref={contentRef}
        className={`w-[100%] transition-all duration-350 ease-in-out overflow-hidden ${
          isOpen ? "max-h-[1000px]" : "max-h-0"
        }`}
        style={{
          maxHeight: isOpen
            ? `${contentRef.current?.scrollHeight ?? 0}px`
            : "0",
        }}
      >
        <div className="flex flex-col items-start justify-start p-[0.5rem] w-[100%]">
          <p className="text-[0.90rem] whitespace-normal">{activity.note}</p>
        </div>
      </div>
    </div>
  );
};

function getActivityIcon(activity) {
  let icon;

  if (activity.type == crmActivityTypes.note) {
    icon = NoteSVG;
  } else if (activity.type == crmActivityTypes.email) {
    icon = EmailSVG;
  } else if (activity.type == crmActivityTypes.outgoing_call) {
    icon = OutgoingCallSVG;
  } else if (activity.type == crmActivityTypes.ingoing_call) {
    icon = IngoingCallSVG;
  } else if (activity.type == crmActivityTypes.sms) {
    icon = SMSSVG;
  } else if (activity.type == crmActivityTypes.whatsapp) {
    icon = WhatsappPNG;
  } else if (activity.type == crmActivityTypes.messenger) {
    icon = MessengerPNG;
  } else if (activity.type == crmActivityTypes.meeting) {
    icon = MeetingSVG;
  }

  return icon;
}

const rolesDisplayText = {
  [userRoles.admin]: locale == locales.italian ? "Ammin" : "Admin",
  [userRoles.salesRepresentative]:
    locale == locales.italian
      ? "Rappresentante di vendita"
      : "Sales Representative",
};
function getActivityText(activity) {
  let sender = "";
  let action = "";

  if (activity.userRole == userRoles.admin) {
    sender = `${rolesDisplayText[activity.userRole]}, `;
  } else if (activity.userRole == userRoles.salesRepresentative) {
    sender = `${rolesDisplayText[activity.userRole]}, ${
      activity.salesRepresentative.firstName
    } ${activity.salesRepresentative.lastName}`;
  }

  if (activity.type == crmActivityTypes.note) {
    action =
      locale == locales.italian ? "ha aggiunto una nota" : `added a note`;
  } else if (activity.type == crmActivityTypes.email) {
    action =
      locale == locales.italian
        ? `ha ricevuto una comunicazione via email`
        : `had an email communication`;
  } else if (activity.type == crmActivityTypes.outgoing_call) {
    action = locale == locales.italian ? "fatto una chiamata" : `made a call`;
    if (isScheduledActivity(activity)) {
      action =
        locale == locales.italian
          ? "programmato una chiamata"
          : `scheduled a call`;
    }
  } else if (activity.type == crmActivityTypes.ingoing_call) {
    action =
      locale == locales.italian ? "ricevuto una chiamata" : `received a call`;
    if (isScheduledActivity(activity)) {
      action =
        locale == locales.italian
          ? "programmato una chiamata"
          : `scheduled a call`;
    }
  } else if (activity.type == crmActivityTypes.sms) {
    action =
      locale == locales.italian
        ? "ha avuto una comunicazione via SMS"
        : `had a sms communication`;
    if (isScheduledActivity(activity)) {
      action =
        locale == locales.italian
          ? "programmato una comunicazione via SMS"
          : `scheduled a sms communication`;
    }
  } else if (activity.type == crmActivityTypes.whatsapp) {
    action =
      locale == locales.italian
        ? "ho avuto una comunicazione su whatsapp"
        : `had a whatsapp communication`;
    if (isScheduledActivity(activity)) {
      action =
        locale == locales.italian
          ? "programmato una comunicazione via whatsapp"
          : `scheduled a whatsapp communication`;
    }
  } else if (activity.type == crmActivityTypes.messenger) {
    action =
      locale == locales.italian
        ? "ha avuto una comunicazione con un messaggero"
        : `had a messenger communication`;
    if (isScheduledActivity(activity)) {
      action =
        locale == locales.italian
          ? "programmato una comunicazione via whatsapp"
          : `scheduled a whatsapp communication`;
    }
  } else if (activity.type == crmActivityTypes.meeting) {
    action =
      locale == locales.italian ? "ha avuto un incontro" : `had a meeting`;
    if (isScheduledActivity(activity)) {
      action =
        locale == locales.italian
          ? "programmato un incontro"
          : `scheduled a meeting`;
    }
  }

  return `${sender} ${action}.`;
}

const MActivity = ({
  typeLocation,
  noteLocation,
  isCallReportLocation,
  attachmentLocation,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.files.isLoading);
  const file = useSelector((state) => state.files.file);
  const contact = useSelector((state) => state.crm.contact);

  const [type, setType] = useState(crmActivityTypes.note);
  const [date, setDate] = useState(null);
  const [note, setNote] = useState(null);
  const [attachment, setAttachment] = useState(null);

  useEffect(() => {
    if (typeLocation) {
      setType(typeLocation);
    }
    if (noteLocation) {
      setNote(noteLocation);
    }
    if (attachmentLocation) {
      setAttachment(attachmentLocation);
    }
  }, [typeLocation, noteLocation, attachmentLocation]);

  useEffect(() => {
    if (type == crmActivityTypes.note) {
      setDate(null);
    }
  }, [type]);

  return (
    <>
      <dialog id="modal_activity" className="modal">
        <form method="dialog" className="modal-box bg-white">
          <div className="flex flex-col items-start justify-center bg-white p-[2%] w-[100%]">
            <CDropDown label={t("type")} value={t(type)}>
              {Object.values(crmActivityTypes).map((_) => (
                <CDropDownMenu
                  title={t(_)}
                  isSelected={type == _}
                  onClick={() => setType(_)}
                />
              ))}
            </CDropDown>
            {type != crmActivityTypes.note ? (
              <>
                <div className="h-[20px]" />
                <p className="text-[12px]">{t("date_(optional)")}</p>
                <CDateTimePicker
                  minDate={new Date(0)}
                  maxDate={new Date()}
                  value={date}
                  onChange={(_) => {
                    setDate(_);
                  }}
                />
              </>
            ) : null}
            <div className="h-[20px]" />
            <div className="flex flex-col justify-start items-start w-[100%]">
              <p className="text-[12px]">{t("note")}</p>
              <textarea
                className="bg-[#F3F6FF] p-[10px] w-[100%] text-[14px] outline-0 rounded-[20px] h-[25dvh] resize-none"
                placeholder={t("enter_note")}
                value={note}
                type="text"
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
            <div className="h-[20px]" />
            {isLoading && file == attachment ? (
              <div className="h-[30px] w-[30px]">
                <CLoader />
              </div>
            ) : null}
            {!attachment ? (
              <>
                <label
                  htmlFor="attachment"
                  className="flex flex-row items-start justify-start w-[100%] p-[2%] bg-[#C0C0C0] rounded-[10px] cursor-pointer"
                >
                  <p className="text-white">{t("add_attachment")}</p>
                  <div className="flex-grow" />
                  <img
                    src={AttachmentAddSVG}
                    className="h-[1.6rem] w-[1.6rem]"
                  />
                </label>
                <input
                  id="attachment"
                  accept=".pdf, .doc, .docx, image/*"
                  className="hidden"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setAttachment(file);
                    }
                  }}
                  type="file"
                  value={""}
                />
              </>
            ) : (
              <div className="flex flex-row items-start justify-start w-[100%]">
                <p className="w-[80%] overflow-hidden text-ellipsis whitespace-nowrap">
                  {attachment.name}
                </p>
                <div className="flex-grow" />
                <img
                  src={CancelSVG}
                  className="h-[1.6rem] w-[1.6rem] cursor-pointer"
                  onClick={() => {
                    setAttachment(null);
                  }}
                />
              </div>
            )}
            <div className="h-[20px]" />
            <CButton
              className="w-[50%] self-center"
              text={t("save")}
              onClick={async () => {
                let attachmentFileKey;

                if (attachment) {
                  await dispatch(setFile(attachment));

                  const [fileName, fileExt] = getFileNameAndExt(
                    attachment.name
                  );

                  const _attachment = await dispatch(
                    uploadFile({
                      name: `${fileName} - ${Date.now()}`,
                    })
                  );

                  attachmentFileKey = _attachment.payload.split(
                    filesURLSplitSequence
                  )[1];
                }

                const res = await dispatch(
                  createActivity({
                    contact_id: contact.id,
                    type,
                    date,
                    note,
                    is_call_report: isCallReportLocation,
                    attachment: attachmentFileKey,
                  })
                );

                if (res.payload) {
                  dispatch(
                    readActivities({
                      contact_id: contact.id,
                    })
                  );
                  window.modal_activity.close();
                }
              }}
            />
          </div>
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
};

export default SActivities;
