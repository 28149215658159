import localforage from "localforage";
import { booleanToString } from "./misc";

class AppResponse {
  success;
  data;
  error;
  errorMessage;
  action;

  constructor(props) {
    Object.assign(this, props);
  }

  static failure({ errorMessage = "Something went off the rails." } = {}) {
    const obj = {
      success: false,
      errorMessage: errorMessage,
    };

    return new AppResponse(obj);
  }

  static parse(map = {}) {
    const obj = {
      success: map["success"] ?? false,
      data: map["data"],
      error: map["error"],
      errorMessage: map["error_message"],
      action: map["action"],
    };

    return new AppResponse(obj);
  }
}

class AppUser {
  id;
  firstName;
  lastName;
  email;
  uuid;
  token;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map = {}) {
    const obj = {
      id: map["id"],
      firstName: map["first_name"],
      lastName: map["last_name"],
      email: map["email"],
      uuid: map["uuid"],
      token: map["token"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],
    };

    return new AppUser(obj);
  }

  cache = async () => {
    await localforage.setItem("appUser", this);
  };
}

class Contact {
  id;
  firstName;
  lastName;
  email;
  phone;
  address;
  website;
  notes;
  type;
  sourceIcon;
  sourceTitle;
  entityType;
  status;
  paymentType;
  paymentTypeStatus;
  isArchived;
  isSalesRepresentativeArchived;
  uuid;
  createdAt;
  updatedAt;

  salesRepresentative;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      firstName: map["first_name"],
      lastName: map["last_name"],
      email: map["email"],
      phone: map["phone"],
      address: map["address"],
      website: map["website"],
      notes: map["notes"],
      type: map["type"],
      sourceIcon: map["source"]?.icon,
      sourceTitle: map["source"]?.title,
      entityType: map["entity_type"],
      status: map["status"],
      paymentType: map["payment_type"],
      paymentTypeStatus: map["payment_type_status"],
      isArchived: map["is_archived"],
      isSalesRepresentativeArchived: map["is_sales_representative_archived"],
      uuid: map["uuid"],
      createdAt: map["created_at"] ? new Date(map["created_at"]) : null,
      updatedAt: map["updated_at"] ? new Date(map["updated_at"]) : null,

      salesRepresentative: map["sales_representative"]
        ? SalesRepresentative.parse(map["sales_representative"])
        : null,
    };

    return new Contact(obj);
  }
}

class SalesRepresentative {
  id;
  firstName;
  lastName;
  email;
  phone;
  coverageArea;
  uuid;
  isActive;
  isArchived;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      firstName: map["first_name"],
      lastName: map["last_name"],
      email: map["email"],
      phone: map["phone"],
      coverageArea: map["coverage_area"],
      uuid: map["uuid"],
      isActive: map["is_active"],
      isArchived: map["is_archived"],
      createdAt: map["created_at"] ? new Date(map["created_at"]) : null,
      updatedAt: map["updated_at"] ? new Date(map["updated_at"]) : null,
    };

    return new SalesRepresentative(obj);
  }
}

class CRMActivity {
  id;
  type;
  date;
  note;
  isCallReport;
  attachment;
  uuid;
  createdAt;
  updatedAt;

  contact;
  userRole;
  salesRepresentative;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      type: map["type"],
      date: map["date"] ? new Date(map["date"]) : null,
      note: map["note"],
      isCallReport: map["is_call_report"],
      attachment: map["attachment"],
      uuid: map["uuid"],
      createdAt: map["created_at"] ? new Date(map["created_at"]) : null,
      updatedAt: map["updated_at"] ? new Date(map["updated_at"]) : null,

      contact: map["contact"] ? Contact.parse(map["contact"]) : null,
      userRole: map["user_role"],
      salesRepresentative: map["sales_representative"]
        ? SalesRepresentative.parse(map["sales_representative"])
        : null,
    };

    return new CRMActivity(obj);
  }
}

class PagesInfo {
  current;
  total;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      current: map["current"],
      total: map["total"],
    };

    return new PagesInfo(obj);
  }
}

class LeadProjectInfo {
  id;
  description;
  powerKit;
  installationKit;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      description: map["description"],
      powerKit: map["power_kit"],
      installationKit: map["installation_kit"],
      createdAt: map["created_at"] ? new Date(map["created_at"]) : null,
      updatedAt: map["updated_at"] ? new Date(map["updated_at"]) : null,
    };

    return new LeadProjectInfo(obj);
  }
}

class LeadFinancialDocument {
  id;
  type;
  note;
  attachment;
  status;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      type: map["type"],
      note: map["note"],
      attachment: map["attachment"],
      status: map["status"],
      createdAt: map["created_at"] ? new Date(map["created_at"]) : null,
      updatedAt: map["updated_at"] ? new Date(map["updated_at"]) : null,
    };

    return new LeadFinancialDocument(obj);
  }
}

class Notification {
  id;
  title;
  type;
  inspectionAssignmentProjectID;
  installationAssignmentProjectID;
  isRead;
  isArchived;
  createdAt;
  updatedAt;

  contact;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      title: map["title"],
      type: map["type"],
      inspectionAssignmentProjectID: map["inspection_assignment_project_id"],
      installationAssignmentProjectID:
        map["installation_assignment_project_id"],
      isRead: map["is_read"],
      isArchived: map["is_archived"],
      createdAt: map["created_at"] ? new Date(map["created_at"]) : null,
      updatedAt: map["updated_at"] ? new Date(map["updated_at"]) : null,

      contact: map["contact"] ? Contact.parse(map["contact"]) : null,
    };

    return new Notification(obj);
  }
}

class NotificationsFilter {
  isArchived;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse({ title = null, isArchived = null }) {
    let _isArchived = isArchived;
    if (typeof _isArchived == "boolean") {
      _isArchived = booleanToString(isArchived);
    }

    const obj = {
      isArchived: _isArchived,
    };

    return new NotificationsFilter(obj);
  }
}

class ContactsFilter {
  type;
  name;
  email;
  phone;
  status;
  acquiredAtStart;
  acquiredAtEnd;
  isSalesRepresentativeArchived;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse({
    type = null,
    name = null,
    email = null,
    phone = null,
    status = null,
    acquiredAtStart = null,
    acquiredAtEnd = null,
    isSalesRepresentativeArchived = null,
  }) {
    let _isSalesRepresentativeArchived = isSalesRepresentativeArchived;
    if (typeof _isSalesRepresentativeArchived == "boolean") {
      _isSalesRepresentativeArchived = booleanToString(
        isSalesRepresentativeArchived
      );
    }

    const obj = {
      type,
      name,
      email,
      phone,
      status,
      acquiredAtStart,
      acquiredAtEnd,
      isSalesRepresentativeArchived: _isSalesRepresentativeArchived,
    };

    return new ContactsFilter(obj);
  }

  toMap() {
    return {
      type: this.type,
      name: this.name,
      email: this.email,
      phone: this.phone,
      status: this.status,
      acquiredAtStart: this.acquiredAtStart,
      acquiredAtEnd: this.acquiredAtEnd,
      isSalesRepresentativeArchived: this.isSalesRepresentativeArchived,
    };
  }
}

export {
  AppResponse,
  AppUser,
  Contact,
  CRMActivity,
  PagesInfo,
  LeadProjectInfo,
  LeadFinancialDocument,
  Notification,
  NotificationsFilter,
  ContactsFilter,
};
