import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { setSelectedRouteTitle } from "../controllers/control_bar";

import BNVCRMSelectedSVG from "../assets/images/bnv_crm.svg";
import BNVCRMUnSelectedSVG from "../assets/images/bnv_crm.svg";
import BNVSettingsSelectedSVG from "../assets/images/bnv_settings.svg";
import BNVSettingsUnselectedSVG from "../assets/images/bnv_settings.svg";

const icons = [
  {
    title: "crm",
    route: "/crm",
    selectedIcon: BNVCRMSelectedSVG,
    unSelectedIcon: BNVCRMUnSelectedSVG,
  },
  {
    title: "settings",
    route: "/settings",
    selectedIcon: BNVSettingsSelectedSVG,
    unSelectedIcon: BNVSettingsUnselectedSVG,
  },
];

function CBottomNavbar({ className }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedRouteTitle = useSelector(
    (state) => state.controlBar.selectedRouteTitle
  );

  return (
    <div
      className={`flex flex-row justify-evenly items-center h-[10dvh] w-[100%] bg-white ${
        className ?? ""
      } absolute bottom-0 sm:hidden`}
    >
      {icons.map((_) => (
        <CBNVListTile
          title={t(_.title)}
          selectedIcon={_.selectedIcon}
          unSelectedIcon={_.unSelectedIcon}
          isSelected={_.title == selectedRouteTitle}
          onClick={() => {
            navigate(_.route);
            dispatch(setSelectedRouteTitle(_.title));
          }}
        />
      ))}
    </div>
  );
}

function CBNVListTile({
  title,
  selectedIcon,
  unSelectedIcon,
  isSelected,
  onClick,
}) {
  return (
    <div
      className="flex flex-col justify-between items-center cursor-pointer"
      onClick={onClick}
    >
      <img
        src={isSelected ? selectedIcon : unSelectedIcon}
        className="h-[1.2rem] w-[1.2rem]"
      />
      <p
        className={`text-[0.9rem] ${
          isSelected ? "text-primary" : "text-[#D2D2D2]"
        }`}
      >
        {title}
      </p>
    </div>
  );
}

export default CBottomNavbar;
