import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setSelectedRouteTitle } from "../controllers/control_bar";
import { readContact, updateContact } from "../controllers/crm";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";

import EditSVG from "../assets/images/edit.svg";
import PersonSVG from "../assets/images/person.svg";
import EmailSVG from "../assets/images/email.svg";
import CallSVG from "../assets/images/call.svg";
import SMSSVG from "../assets/images/sms.svg";
import LeadPaymentTypeSVG from "../assets/images/lead_payment_type.svg";
import DocumentsSVG from "../assets/images/documents.svg";
import WhatsappPNG from "../assets/images/whatsapp.png";

import {
  contactTypes,
  crmLeadStatusImages,
  dateTimeFormatter,
} from "../utils/constants";

function SContactInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const contactID = searchParams.get("id");

  const contact = useSelector((state) => state.crm.contact);

  const [isArchived, setIsArchived] = useState(false);

  useEffect(() => {
    _init();
  }, []);

  useEffect(() => {
    if (contact) {
      setIsArchived(contact.isSalesRepresentativeArchived);
    }
  }, [contact]);

  const _init = async () => {
    if (!contactID) {
      navigate(-1);
      return;
    }

    dispatch(setSelectedRouteTitle("crm"));
    dispatch(
      readContact({
        id: contactID,
      })
    );
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%]">
          <div className="flex flex-row items-center justify-between w-[100%]">
            <p className="text-[20px] font-[600]">{t("contact_info")}</p>
            {contact?.type == contactTypes.lead ||
            contact?.type == contactTypes.acquired_lead ? (
              <CButton
                text="Project info"
                onClick={() => {
                  navigate(`/lead_project_info?lead_id=${contactID}`);
                }}
              />
            ) : null}
            <CButton
              text="Activites"
              onClick={() => {
                navigate(`/activities?contact_id=${contactID}`);
              }}
            />
          </div>
          <div className="h-[20px]" />
          {!contact ? (
            <CLoader />
          ) : (
            <div className="flex flex-col items-start justify-start w-[100%] h-[100%] p-[2%] overflow-scroll">
              <img
                className="w-[100%] h-[30%] object-contain"
                src={PersonSVG}
              />
              <div className="flex flex-row items-center justify-between w-[100%]">
                <div className="flex flex-row items-start justify-start">
                  <p className="text-[1rem]">
                    {`${contact.firstName ?? "N/A"} ${
                      contact.lastName ?? "N/A"
                    }`}
                  </p>
                </div>
                <div
                  className={`flex flex-row items-center justify-center rounded-[2rem] px-[1rem] py-[0.2rem] ml-[0.2rem] ${
                    contact.type == contactTypes.client
                      ? "bg-blue-400"
                      : contact.type == contactTypes.lead
                      ? "bg-green-400"
                      : contact.type == contactTypes.acquired_lead
                      ? "bg-yellow-400"
                      : "bg-white"
                  }`}
                >
                  <p className="text-[0.9rem] font-[600] whitespace-nowrap">
                    {t(contact.type)}
                  </p>
                </div>
              </div>
              <p className="text-[0.90rem]">{`Email: ${
                contact.email ?? "N/A"
              }`}</p>
              <p className="text-[0.90rem]">{`Phone: ${
                contact.phone ?? "N/A"
              }`}</p>
              {contact.type == contactTypes.lead ||
              contact.type == contactTypes.acquired_lead ? (
                <div className="flex flex-row items-center justify-between w-[100%]">
                  <p className="text-[0.9rem]">Status</p>
                  {contact.status ? (
                    <img
                      src={crmLeadStatusImages[contact.status]}
                      className="h-[1.5rem] w-[1.5rem] rounded-[1rem] object-contain"
                    />
                  ) : (
                    <p className="text-[0.9rem]">N/A</p>
                  )}
                </div>
              ) : null}
              <p className="text-[0.90rem]">{`Created: ${dateTimeFormatter.format(
                contact.createdAt
              )}`}</p>
              <div className="mt-[1rem]" />
              {contact.type == contactTypes.lead ||
              contact.type == contactTypes.acquired_lead ? (
                <CCommunication
                  icon={EditSVG}
                  title="Status"
                  description="Change lead status."
                  onClick={() => {
                    navigate(`/lead_status?contact_id=${contactID}`);
                  }}
                />
              ) : null}
              {(contact.type == contactTypes.lead ||
                contact.type == contactTypes.acquired_lead) &&
              !contact.paymentType ? (
                <CCommunication
                  icon={LeadPaymentTypeSVG}
                  title="Payment Type"
                  description="Select payment type"
                  onClick={() => {
                    navigate(`/lead_payment_type?contact_id=${contactID}`);
                  }}
                />
              ) : null}
              {(contact.type == contactTypes.lead ||
                contact.type == contactTypes.acquired_lead) &&
              contact.paymentType ? (
                <CCommunication
                  icon={DocumentsSVG}
                  title="Upload Documents"
                  description={`Payment Type: ${t(contact.paymentType)}`}
                  onClick={() => {
                    navigate(
                      `/lead_financial_documents?contact_id=${contactID}`
                    );
                  }}
                />
              ) : null}
              {contact.email ? (
                <CCommunication
                  icon={EmailSVG}
                  title="Email"
                  description="Send an email."
                  onClick={() => {
                    window.open(`mailto:${contact.email}`, "_self");
                  }}
                />
              ) : null}
              {contact.phone ? (
                <CCommunication
                  icon={CallSVG}
                  title="Call"
                  description="Make a phone call."
                  onClick={() => {
                    navigate(`/call?contact_id=${contactID}`);
                  }}
                />
              ) : null}
              {contact.phone ? (
                <CCommunication
                  icon={SMSSVG}
                  title="SMS"
                  description="Send a text message."
                  onClick={() => {
                    window.open(`sms:${contact.phone}`, "_self");
                  }}
                />
              ) : null}
              {contact.phone ? (
                <CCommunication
                  icon={WhatsappPNG}
                  title="Whatsapp"
                  description="Open a whatsapp chat."
                  onClick={() => {
                    window.open(`https://wa.me/${contact.phone}`, "_blank");
                  }}
                />
              ) : null}
              <div className="flex flex-row items-center justify-between w-[100%]">
                <p className="text-[16px] font-[800]">{t("archived")}</p>
                <input
                  checked={isArchived}
                  type="checkbox"
                  className="toggle toggle-accent"
                  onChange={(e) => {
                    setIsArchived(e.target.checked);

                    dispatch(
                      updateContact({
                        id: contact.id,
                        is_sales_representative_archived: e.target.checked,
                      })
                    );
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </CContainer>
  );
}

function CCommunication({ icon, title, description, onClick }) {
  return (
    <div
      className="flex flex-row items-center justify-center w-[100%] p-[2%] my-[0.5rem] bg-white border-[#F5F5F5] border-solid border-[1px] rounded-[5px] cursor-pointer flex-shrink-0"
      onClick={onClick}
    >
      <img className="w-[40px] h-[40px] object-contain" src={icon} />
      <div className="ml-[1.5rem]" />
      <div className="flex flex-col items-start justify-start w-[100%]">
        <p className="text-[1rem]">{title}</p>
        <p className="text-[0.9rem]">{description}</p>
      </div>
    </div>
  );
}

export default SContactInfo;
