import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";
import toast from "react-hot-toast";

const initialState = {
  isLoading: false,
  file: null,
  files: [],
};

const uploadFile = createAsyncThunk(
  "files/uploadFile",
  async (args, { getState }) => {
    try {
      const { name } = args;

      const state = getState();

      if (!state.files.file) {
        toast.error("No file to upload.");
        return;
      }

      const fileURL = await AppNetworkRest.uploadFile({
        name,
        file: state.files.file,
      });

      return fileURL;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const downloadFile = createAsyncThunk(
  "files/downloadFile",
  async (args, { getState }) => {
    const { file } = args;

    try {
      const _file = await AppNetworkRest.downloadFile({
        file,
      });
      return _file;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setFile: (state, action) => {
      state.file = action.payload;
    },
    setFiles: (state, action) => {
      const { file, status } = action.payload;

      let files = [...state.files];

      if (status) {
        files.push(file);
      } else {
        files = files.filter((_) => _ != file);
      }

      state.files = files;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadFile.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(downloadFile.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(downloadFile.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export { uploadFile, downloadFile };

export const { setFile, setFiles } = filesSlice.actions;

export default filesSlice.reducer;
