import { createBrowserRouter } from "react-router-dom";

import SSplash from "../screens/splash";
import SLogin from "../screens/login";
import SCRM from "../screens/crm";
import SContactInfo from "../screens/contact_info";
import SCall from "../screens/call";
import SCallOutcome from "../screens/call_outcome";
import SLeadStatus from "../screens/lead_status";
import SActivities from "../screens/activities";
import SLeadProjectInfo from "../screens/lead_project_info";
import SLeadPaymentType from "../screens/lead_payment_type";
import SLeadFinancialDocuments from "../screens/lead_financial_documents";
import SSettings from "../screens/settings";
import SResetPassword from "../screens/reset_password";
import SForgotPassword1 from "../screens/forgot_password_1";
import SForgotPassword2 from "../screens/forgot_password_2";
import SForgotPassword3 from "../screens/forgot_password_3";
import SAbout from "../screens/about";
import SNotifications from "../screens/notifications";

const splashRouter = createBrowserRouter([
  {
    path: "*",
    element: <SSplash />,
  },
]);

const authRouter = createBrowserRouter([
  {
    path: "*",
    element: <SLogin />,
  },
  {
    path: "/login",
    element: <SLogin />,
  },
  // {
  //   path: "/forgot_password_1",
  //   element: <SForgotPassword1 />,
  // },
  // {
  //   path: "/forgot_password_2",
  //   element: <SForgotPassword2 />,
  // },
  // {
  //   path: "/forgot_password_3",
  //   element: <SForgotPassword3 />,
  // },
]);

const userRouter = createBrowserRouter([
  {
    path: "*",
    element: <SCRM />,
  },
  {
    path: "/",
    element: <SCRM />,
  },
  {
    path: "/crm",
    element: <SCRM />,
  },
  {
    path: "/contact_info",
    element: <SContactInfo />,
  },
  {
    path: "/call",
    element: <SCall />,
  },
  {
    path: "/call_outcome",
    element: <SCallOutcome />,
  },
  {
    path: "/lead_status",
    element: <SLeadStatus />,
  },
  {
    path: "/activities",
    element: <SActivities />,
  },
  {
    path: "/lead_project_info",
    element: <SLeadProjectInfo />,
  },
  {
    path: "/lead_payment_type",
    element: <SLeadPaymentType />,
  },
  {
    path: "/lead_financial_documents",
    element: <SLeadFinancialDocuments />,
  },
  {
    path: "/settings",
    element: <SSettings />,
  },
  {
    path: "/about",
    element: <SAbout />,
  },
  // {
  //   path: "/reset_password",
  //   element: <SResetPassword />,
  // },
  {
    path: "/notifications",
    element: <SNotifications />,
  },
]);

export { splashRouter, authRouter, userRouter };
