import axios from "axios";
import toast from "react-hot-toast";
import localforage from "localforage";

import {
  AppResponse,
  AppUser,
  CRMActivity,
  Contact,
  PagesInfo,
  LeadProjectInfo,
  LeadFinancialDocument,
  Notification,
} from "./models";
import { appResponseActions } from "./constants";
import { downloadBlob } from "./misc";

const RequestTypes = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

// const serverURL = "http://localhost:4001";
const serverURL = "https://ultrasolar-dev-server.umarbinayaz.com";
// const serverURL = "https://server.ultrasolar.app";

async function login({ email, password }) {
  try {
    const url = `${serverURL}/auth/sales_representatives/login`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { email, password },
    });

    if (res.success) {
      let appUser = AppUser.parse(res.data);
      return appUser;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function resetPassword({ old_password, new_password }) {
  try {
    const url = `${serverURL}/auth/reset_password`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { old_password, new_password },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function forgotPassword1({ email }) {
  try {
    const url = `${serverURL}/auth/forgot_password_1`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        email,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function forgotPassword2({ email, code }) {
  try {
    const url = `${serverURL}/auth/forgot_password_2`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        email,
        code,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function forgotPassword3({ email, password }) {
  try {
    const url = `${serverURL}/auth/forgot_password_3`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        email,
        password,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readContacts({
  page_no,
  type,
  name,
  email,
  phone,
  status,
  acquired_at_start,
  acquired_at_end,
  is_sales_representative_archived,
}) {
  try {
    const url = `${serverURL}/contacts/read/sales_representatives`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        page_no,
        type,
        name,
        email,
        phone,
        status,
        acquired_at_start,
        acquired_at_end,
        is_sales_representative_archived,
      },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const contacts = res.data.array.map((_) => Contact.parse(_));

      return [pagesInfo, contacts];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readContact({ id }) {
  try {
    const url = `${serverURL}/contacts/read/${id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      if (res.data) {
        const contact = Contact.parse(res.data);
        return contact;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function updateContact({
  id,
  status,
  payment_type,
  is_sales_representative_archived,
}) {
  try {
    const url = `${serverURL}/contacts/update_sales_rep`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        id,
        status,
        payment_type,
        is_sales_representative_archived,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readTwilioCallToken() {
  try {
    const url = `${serverURL}/communication/twilio_call_token`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createCRMActivity({
  contact_id,
  type,
  date,
  note,
  is_call_report,
  attachment,
}) {
  try {
    const url = `${serverURL}/crm_activities/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        contact_id,
        type,
        date,
        note,
        is_call_report,
        attachment,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readCRMActivities({ page_no, contact_id, crm_deal_id }) {
  try {
    const url = `${serverURL}/crm_activities/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { page_no, contact_id, crm_deal_id },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const crmActivities = res.data.array.map((_) => CRMActivity.parse(_));

      return [pagesInfo, crmActivities];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function uploadFile({ name, file }) {
  try {
    const url = `${serverURL}/files/upload`;

    const headers = await _headers();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", file);

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: formData,
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function downloadFile({ file }) {
  try {
    const url = `${serverURL}/files/download?url=${file}`;

    const headers = await _headers();

    const res = await axios({
      method: RequestTypes.get,
      url: url,
      headers: headers,
      responseType: "blob",
    });

    const data = res.data;

    if (data) {
      var index = file.lastIndexOf("/") + 1;
      var name = file.substr(index);

      downloadBlob({ blob: data, name: name });
    }
  } catch (e) {
    return null;
  }
}

async function createLeadProjectInfo({
  lead_id,
  description,
  power_kit,
  installation_kit,
}) {
  try {
    const url = `${serverURL}/lead_project_infos/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        lead_id,
        description,
        power_kit,
        installation_kit,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readLeadProjectInfo({ lead_id }) {
  try {
    const url = `${serverURL}/lead_project_infos/read/${lead_id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      if (res.data) {
        const leadProjectInfo = LeadProjectInfo.parse(res.data);

        return leadProjectInfo;
      }

      return null;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createLeadFinancialDocument({
  lead_id,
  type,
  note,
  attachment,
}) {
  try {
    const url = `${serverURL}/lead_finance_documents/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        lead_id,
        type,
        note,
        attachment,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readLeadFinancialDocuments({ lead_id }) {
  try {
    const url = `${serverURL}/lead_finance_documents/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        lead_id,
      },
    });

    if (res.success) {
      const leadFinancialDocuments = res.data.map((_) =>
        LeadFinancialDocument.parse(_)
      );

      return leadFinancialDocuments;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}

async function updateLeadFinancialDocument({
  lead_id,
  type,
  note,
  attachment,
}) {
  try {
    const url = `${serverURL}/lead_finance_documents/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        lead_id,
        type,
        note,
        attachment,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readNotifications({ page_no, is_archived }) {
  try {
    const url = `${serverURL}/notifications/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        page_no,
        is_archived,
      },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const notifications = res.data.array.map((_) => Notification.parse(_));

      return [pagesInfo, notifications];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function updateNotification({ id, is_archived }) {
  try {
    const url = `${serverURL}/notifications/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        id,
        is_archived,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function _request({ requestType, url, headers, body, orotund = true }) {
  let appResponse;

  try {
    const res = await axios({
      method: requestType,
      url: url,
      data: body,
      headers: headers,
    });

    const data = res.data;

    appResponse = AppResponse.parse(data);

    if (appResponse.action == appResponseActions.logout) {
      localStorage.removeItem("AppStorage/appUser");
      window.location = "/";
      toast.error("Session expired. Login again.");
    }
  } catch (e) {
    appResponse = AppResponse.failure();
  }

  if (!appResponse.success && orotund) {
    toast.error(appResponse.errorMessage);
  }

  return appResponse;
}

async function _headers(props = { parseAppUser: true }) {
  const obj = {};

  obj["x-access-token"] = `Basic ${process.env.REACT_APP_X_ACCESS_TOKEN}`;

  if (props.parseAppUser) {
    const appUser = await localforage.getItem("appUser");

    if (appUser != null) {
      obj["authorization"] = `Bearer ${appUser.token}`;
    }
  }

  return obj;
}

export {
  login,
  resetPassword,
  forgotPassword1,
  forgotPassword2,
  forgotPassword3,
  readContacts,
  readContact,
  updateContact,
  readTwilioCallToken,
  createCRMActivity,
  readCRMActivities,
  uploadFile,
  downloadFile,
  createLeadProjectInfo,
  readLeadProjectInfo,
  createLeadFinancialDocument,
  readLeadFinancialDocuments,
  updateLeadFinancialDocument,
  readNotifications,
  updateNotification,
};
