import React, { useState, useEffect, useRef } from "react";

import { useOutsideClick } from "../utils/hooks";

function CDropDown({ label, value, disabled, children }) {
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  const [isOpen, setIsOpen] = useState(false);
  const [parsedChildren, setParsedChildren] = useState(null);

  useEffect(() => {
    const _parsedChildren = children.map((_) =>
      React.cloneElement(_, { setIsOpen: setIsOpen })
    );
    setParsedChildren(_parsedChildren);
  }, [children]);

  return (
    <div className="w-[100%]">
      <label class="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div class="relative mt-[0.2rem] mb-[1rem] w-[100%]" ref={ref}>
        <button
          type="button"
          class="relative cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6 w-[100%] min-h-[2rem]"
          onClick={() => {
            if (disabled) {
              return;
            }
            setIsOpen(!isOpen);
          }}
        >
          <span class="ml-3 block truncate">{value ?? "N/A"}</span>
        </button>
        {isOpen ? (
          <ul
            class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            tabindex="-1"
          >
            {parsedChildren}
          </ul>
        ) : null}
      </div>
    </div>
  );
}

function CDropDownMenu({ title, isSelected, onClick, setIsOpen }) {
  return (
    <li
      class="text-[0.9rem] text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9"
      role="option"
      onClick={() => {
        onClick();
        setIsOpen(false);
      }}
    >
      <span
        class={`ml-3 block truncate ${
          !isSelected ? "font-[400]" : "font-[800]"
        }`}
      >
        {title}
      </span>
    </li>
  );
}

export { CDropDown, CDropDownMenu };
