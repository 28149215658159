import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setSelectedRouteTitle } from "../controllers/control_bar";
import { readContact } from "../controllers/crm";

import CContainer from "../components/container";
import CLoader from "../components/loader";

import CallPositivePNG from "../assets/images/call_positive.png";
import CallNeutralPNG from "../assets/images/call_neutral.png";
import CallNegativePNG from "../assets/images/call_negative.png";
import NoteSVG from "../assets/images/note.svg";
import OutgoingCallSVG from "../assets/images/outgoing_call.svg";
import MeetingSVG from "../assets/images/meeting.svg";
import { callOutcomes, crmActivityTypes } from "../utils/constants";

function SCallOutcome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const location = useLocation();

  const contactID = searchParams.get("contact_id");
  const { file } = location.state ?? {};

  const contact = useSelector((state) => state.crm.contact);

  const [outcome, setOutcome] = useState(callOutcomes.positive);

  useEffect(() => {
    _init();
  }, []);

  const _init = async () => {
    if (!contactID) {
      navigate(-1);
      return;
    }

    if (!file) {
      navigate(-1);
      return;
    }

    dispatch(setSelectedRouteTitle("crm"));
    dispatch(
      readContact({
        id: contactID,
      })
    );
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%]">
          <div className="flex flex-row items-center justify-between w-[100%]">
            <p className="text-[20px] font-[600]">{t("call_outcome")}</p>
          </div>
          <div className="h-[20px]" />
          {!contact ? (
            <CLoader />
          ) : (
            <div className="flex flex-col self-center items-center justify-start w-[100%] h-[100%]">
              <div className="flex flex-row self-center items-center justify-between w-[100%] h-[30%] p-[2%] bg-white shadow-lg border-[#F5F5F5] border-solid border-[1px] cursor-pointer">
                <COutcome
                  icon={CallPositivePNG}
                  outcome={callOutcomes.positive}
                  isSelected={outcome == callOutcomes.positive}
                  onClick={(_) => {
                    setOutcome(_);
                  }}
                />
                <COutcome
                  icon={CallNeutralPNG}
                  outcome={callOutcomes.neutral}
                  isSelected={outcome == callOutcomes.neutral}
                  onClick={(_) => {
                    setOutcome(_);
                  }}
                />
                <COutcome
                  icon={CallNegativePNG}
                  outcome={callOutcomes.negative}
                  isSelected={outcome == callOutcomes.negative}
                  onClick={(_) => {
                    setOutcome(_);
                  }}
                />
              </div>
              <div className="flex flex-col self-center items-center justify-start w-[100%] h-[65%] px-[2%] overflow-scroll">
                {outcome == callOutcomes.positive ? (
                  <>
                    <COutcomeAction
                      icon={NoteSVG}
                      title={t("request_more_info.")}
                      onClick={() => {
                        navigate(`/activities?contact_id=${contactID}`, {
                          state: {
                            type: crmActivityTypes.note,
                            note: t("request_more_info."),
                            isCallReport: true,
                            attachment: file,
                          },
                        });
                      }}
                    />
                    <COutcomeAction
                      icon={OutgoingCallSVG}
                      title={t("call_back_to_organize_meeting.")}
                      onClick={() => {
                        navigate(`/activities?contact_id=${contactID}`, {
                          state: {
                            type: crmActivityTypes.outgoing_call,
                            note: t("call_back_to_organize_meeting."),
                            isCallReport: true,
                            attachment: file,
                          },
                        });
                      }}
                    />
                    <COutcomeAction
                      icon={MeetingSVG}
                      title={t("appointment_arranged.")}
                      onClick={() => {
                        navigate(`/activities?contact_id=${contactID}`, {
                          state: {
                            type: crmActivityTypes.meeting,
                            note: t("appointment_arranged."),
                            isCallReport: true,
                            attachment: file,
                          },
                        });
                      }}
                    />
                    <COutcomeAction
                      icon={NoteSVG}
                      title={t("other.")}
                      onClick={() => {
                        navigate(`/activities?contact_id=${contactID}`, {
                          state: {
                            type: crmActivityTypes.note,
                            isCallReport: true,
                            attachment: file,
                          },
                        });
                      }}
                    />
                  </>
                ) : outcome == callOutcomes.neutral ? (
                  <>
                    <COutcomeAction
                      icon={OutgoingCallSVG}
                      title={t("no_answer,_call_back_later.")}
                      onClick={() => {
                        navigate(`/activities?contact_id=${contactID}`, {
                          state: {
                            type: crmActivityTypes.outgoing_call,
                            note: t("no_answer,_call_back_later."),
                            isCallReport: true,
                            attachment: file,
                          },
                        });
                      }}
                    />
                    <COutcomeAction
                      icon={OutgoingCallSVG}
                      title={t("call_back_later.")}
                      onClick={() => {
                        navigate(`/activities?contact_id=${contactID}`, {
                          state: {
                            type: crmActivityTypes.outgoing_call,
                            note: t("call_back_later."),
                            isCallReport: true,
                            attachment: file,
                          },
                        });
                      }}
                    />
                    <COutcomeAction
                      icon={NoteSVG}
                      title={t("request_further_details_without_obligation.")}
                      onClick={() => {
                        navigate(`/activities?contact_id=${contactID}`, {
                          state: {
                            type: crmActivityTypes.note,
                            note: t(
                              "request_further_details_without_obligation."
                            ),
                            isCallReport: true,
                            attachment: file,
                          },
                        });
                      }}
                    />
                    <COutcomeAction
                      icon={NoteSVG}
                      title={t("left_voicemail_message.")}
                      onClick={() => {
                        navigate(`/activities?contact_id=${contactID}`, {
                          state: {
                            type: crmActivityTypes.note,
                            note: t("left_voicemail_message."),
                            isCallReport: true,
                            attachment: file,
                          },
                        });
                      }}
                    />
                    <COutcomeAction
                      icon={NoteSVG}
                      title={t("other.")}
                      onClick={() => {
                        navigate(`/activities?contact_id=${contactID}`, {
                          state: {
                            type: crmActivityTypes.note,
                            isCallReport: true,
                            attachment: file,
                          },
                        });
                      }}
                    />
                  </>
                ) : outcome == callOutcomes.negative ? (
                  <>
                    <COutcomeAction
                      icon={NoteSVG}
                      title={t("invalid.")}
                      onClick={() => {
                        navigate(`/activities?contact_id=${contactID}`, {
                          state: {
                            type: crmActivityTypes.note,
                            isCallReport: true,
                            attachment: file,
                          },
                        });
                      }}
                    />
                    <COutcomeAction
                      icon={NoteSVG}
                      title={t("lead_is_not_interested_in_the_proposal.")}
                      onClick={() => {
                        navigate(`/activities?contact_id=${contactID}`, {
                          state: {
                            type: crmActivityTypes.note,
                            note: t("lead_is_not_interested_in_the_proposal."),
                            isCallReport: true,
                            attachment: file,
                          },
                        });
                      }}
                    />
                    <COutcomeAction
                      icon={NoteSVG}
                      title={t("lead_received_better_quote.")}
                      onClick={() => {
                        navigate(`/activities?contact_id=${contactID}`, {
                          state: {
                            type: crmActivityTypes.note,
                            note: t("lead_received_better_quote."),
                            isCallReport: true,
                            attachment: file,
                          },
                        });
                      }}
                    />
                    <COutcomeAction
                      icon={NoteSVG}
                      title={t("other.")}
                      onClick={() => {
                        navigate(`/activities?contact_id=${contactID}`, {
                          state: {
                            type: crmActivityTypes.note,
                            isCallReport: true,
                            attachment: file,
                          },
                        });
                      }}
                    />
                  </>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </CContainer>
  );
}

function COutcome({ icon, outcome, isSelected, onClick }) {
  return (
    <div className="flex flex-col items-center justify-center">
      <img
        className="w-[5rem] h-[5rem] object-contain rounded-[2.5rem]"
        src={icon}
      />
      <div className="my-[1rem]" />
      <input
        type="checkbox"
        checked={isSelected}
        className="checkbox checkbox-lg"
        onClick={() => {
          onClick(outcome);
        }}
      />
    </div>
  );
}

function COutcomeAction({ title, icon, onClick }) {
  return (
    <div
      className="flex flex-row items-start justify-start w-[100%] px-[1rem] py-[1rem] my-[0.5rem] bg-[#F1F2F3] rounded-[1rem] cursor-pointer"
      onClick={onClick}
    >
      <img className="w-[2rem] h-[2rem] object-contain" src={icon} />
      <div className="mx-[1rem]" />
      <p className="text-[1rem]">{title}</p>
    </div>
  );
}

export default SCallOutcome;
