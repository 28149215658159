import CallPositivePNG from "../assets/images/call_positive.png";
import CallNeutralPNG from "../assets/images/call_neutral.png";
import CallNegativePNG from "../assets/images/call_negative.png";

const locales = {
  italian: "it",
  english: "en",
};

const locale = localStorage.getItem("locale") ?? locales.italian;

const projectStatuses = {
  created: "created",
  assigned: "assigned",
  inspected: "inspected",
  installed: "installed",
};

const inspectionTypes = {
  form: "form",
  images: "images",
};

const inspectionTechnicianTypes = {
  inspection: "inspection",
  installation: "installation",
  inspection_installation: "inspection_installation",
};

const installationTypes = {
  form: "form",
  images: "images",
};

const _timeFormat = {
  hourCycle: "h23",
  hour: "2-digit",
  minute: "2-digit",
};

const dateFormatter = new Intl.DateTimeFormat("en-GB", {
  timeZone: "Europe/Rome",
  day: "numeric",
  month: "numeric",
  year: "numeric",
});

const timeFormatter = new Intl.DateTimeFormat("en-GB", {
  ..._timeFormat,
});

const dateTimeFormatter = new Intl.DateTimeFormat("en-GB", {
  ..._timeFormat,
  day: "numeric",
  month: "numeric",
  year: "numeric",
});

const splitSequence = ".&&.";
const filesURLSplitSequence = "amazonaws.com/";

const appResponseActions = {
  logout: "logout",
};

const maintenanceProjectStatuses = {
  created: "created",
  assigned: "assigned",
  submitted: "submitted",
};

const maintenanceTypes = {
  form: "form",
  images: "images",
};

const userRoles = {
  admin: "admin",
  salesRepresentative: "salesRepresentative",
};

const contactTypes = {
  client: "client",
  lead: "lead",
  acquired_lead: "acquired_lead",
};

const acquisitionSources = {
  manual: "manual",
  website: "website",
  google_ads: "google_ads",
  social_ads: "social_ads",
  phone_call: "phone_call",
};
const acquisitionSourcesEN = {
  manual: "Manual",
  website: "Website",
  google_ads: "Google ads",
  social_ads: "Social ads",
  phone_call: "Phone call",
};
const acquisitionSourcesIT = {
  manual: "Manuale",
  website: "Sito web",
  google_ads: "Annunci Google",
  social_ads: "Annunci sociali",
  phone_call: "Telefonata",
};

const crmActivityTypes = {
  note: "note",
  email: "email",
  outgoing_call: "outgoing_call",
  ingoing_call: "ingoing_call",
  sms: "sms",
  whatsapp: "whatsapp",
  messenger: "messenger",
  meeting: "meeting",
};

const crmDealStatuses = {
  acquisition: "acquisition",
  engagement: "engagement",
  conversion: "conversion",
  retention: "retention",
};

const callOutcomes = {
  positive: "positive",
  neutral: "neutral",
  negative: "negative",
};

const crmLeadStatuses = {
  positive: "positive",
  neutral: "neutral",
  negative: "negative",
};
const crmLeadStatusesEN = {
  positive: "Positive",
  neutral: "Neutral",
  negative: "Negative",
};
const crmLeadStatusesIT = {
  positive: "positivo",
  neutral: "neutro",
  negative: "negativo",
};

const crmLeadStatusImages = {
  positive: CallPositivePNG,
  neutral: CallNeutralPNG,
  negative: CallNegativePNG,
};

const leadPaymentTypes = {
  bank_transfer: "bank_transfer",
  financial_company: "financial_company",
  financial_company_with_guarantor: "financial_company_with_guarantor",
};

const leadFinancialDocumentTypesBankTransfer = {
  contract: "contract",
  identity_document: "identity_document",
  health_card: "health_card",
  land_registration_book: "land_registration_book",
  bill: "bill",
};

const leadFinancialDocumentTypesFinancialCompany = {
  contract: "contract",
  identity_document: "identity_document",
  health_card: "health_card",
  land_registration_book: "land_registration_book",
  bill: "bill",
  income_document: "income_document",
  financial_company_form: "financial_company_form",
};

const leadFinancialDocumentTypesFinancialCompanyWithGuarantor = {
  contract: "contract",
  identity_document: "identity_document",
  health_card: "health_card",
  land_registration_book: "land_registration_book",
  bill: "bill",
  income_document: "income_document",
  financial_company_form: "financial_company_form",
  identity_document_guarantor: "identity_document_guarantor",
  health_card_guarantor: "health_card_guarantor",
  income_document_guarantor: "income_document_guarantor",
  financial_company_form_with_guarantor:
    "financial_company_form_with_guarantor",
};

const leadFinancialDocumentStatuses = {
  uploaded: "uploaded",
  rejected: "rejected",
  approved: "approved",
};

const notificationTypes = {
  activity: "activity",
  lead_finance_document_approved: "lead_finance_document_approved",
  lead_finance_document_rejected: "lead_finance_document_rejected",
};

export {
  locale,
  projectStatuses,
  inspectionTypes,
  inspectionTechnicianTypes,
  installationTypes,
  dateFormatter,
  timeFormatter,
  dateTimeFormatter,
  locales,
  splitSequence,
  filesURLSplitSequence,
  appResponseActions,
  maintenanceProjectStatuses,
  maintenanceTypes,
  userRoles,
  contactTypes,
  acquisitionSources,
  acquisitionSourcesEN,
  acquisitionSourcesIT,
  crmActivityTypes,
  crmDealStatuses,
  callOutcomes,
  crmLeadStatuses,
  crmLeadStatusesEN,
  crmLeadStatusesIT,
  crmLeadStatusImages,
  leadPaymentTypes,
  leadFinancialDocumentTypesBankTransfer,
  leadFinancialDocumentTypesFinancialCompany,
  leadFinancialDocumentTypesFinancialCompanyWithGuarantor,
  leadFinancialDocumentStatuses,
  notificationTypes,
};
