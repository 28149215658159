import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";
import { i18n } from "../utils/i18n";

const initialState = {
  isLoading: false,
  device: null,
};

const readTwilioCallToken = createAsyncThunk(
  "communication/readTwilioCallToken",
  async (args) => {
    try {
      const token = await AppNetworkRest.readTwilioCallToken(args);
      return token;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const communicationSlice = createSlice({
  name: "communication",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(readTwilioCallToken.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readTwilioCallToken.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export { readTwilioCallToken };

export default communicationSlice.reducer;
