import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setSelectedRouteTitle } from "../controllers/control_bar";
import { readContacts, setContactsFilter } from "../controllers/crm";

import CContainer from "../components/container";
import CButton from "../components/button";
import CFilterBadges from "../components/filter_badges";
import { CDropDown, CDropDownMenu } from "../components/dropdown";
import CInput from "../components/input";

import {
  contactTypes,
  dateTimeFormatter,
  locale,
  locales,
} from "../utils/constants";
import { ContactsFilter } from "../utils/models";

import FiltersBtnSVG from "../assets/images/filters_btn.svg";
import CancelSVG from "../assets/images/cancel.svg";

const tabs = {
  Contacts: "Contacts",
  Deals: "Deals",
};
function SCRM() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.crm.isLoading);
  const pagesInfo = useSelector((state) => state.crm.contactsPagesInfo);
  const contacts = useSelector((state) => state.crm.contacts);
  const contactsFilter = useSelector((state) => state.crm.contactsFilter);

  const [tab, setTab] = useState(tabs.Contacts);

  useEffect(() => {
    _init();
  }, []);

  const _init = async () => {
    dispatch(setSelectedRouteTitle("crm"));

    const _contactsFilter = ContactsFilter.parse({
      isSalesRepresentativeArchived: false,
    });

    await dispatch(setContactsFilter(_contactsFilter));
    dispatch(readContacts());
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%]">
          <p className="text-[20px] font-[500] self-center">{t("crm")}</p>
          <div className="h-[20px]" />
          <>
            <div role="tablist" className="tabs tabs-bordered w-[100%]">
              {Object.keys(tabs).map((_) => (
                <a
                  role="tab"
                  className={`tab ${tab == _ ? "tab-active" : ""}`}
                  onClick={() => {
                    setTab(_);
                  }}
                >
                  {_}
                </a>
              ))}
            </div>
            <div
              className={`flex flex-col items-start justify-start p-[2%] w-[100%] h-[100%] overflow-scroll`}
            >
              {tab == tabs.Contacts ? (
                <>
                  <div className="flex flex-row items-center justify-start min-h-[3rem] w-[100%] py-[0.5rem] overflow-x-scroll">
                    <CButton
                      className="!px-[2rem] !py-[0.5rem]"
                      text={t("filters")}
                      icon={FiltersBtnSVG}
                      onClick={() => {
                        window.modal_contacts_filters.showModal();
                      }}
                    />
                    <CFilterBadges
                      filter={contactsFilter}
                      filterMap={{
                        type: "Type",
                        name: "Name",
                        email: "Email",
                        phone: "Phone",
                        isSalesRepresentativeArchived: "Archived",
                      }}
                      filterVMap={{
                        type: contactsFilter.type
                          ? t(contactsFilter.type)
                          : null,
                      }}
                      onCancel={async (remove) => {
                        const _contactsFilter = ContactsFilter.parse(
                          contactsFilter.toMap()
                        );
                        _contactsFilter[remove] = null;

                        await dispatch(setContactsFilter(_contactsFilter));
                        await dispatch(readContacts());
                      }}
                    />
                  </div>

                  {contacts.map((_) => (
                    <CContact contact={_} />
                  ))}
                  {pagesInfo.current < pagesInfo.total ? (
                    <>
                      <div className="mt-[0.5rem]" />
                      <CButton
                        isLoading={isLoading}
                        className="w-[50%] self-center"
                        text={t("load_more")}
                        onClick={async () => {
                          dispatch(
                            readContacts({
                              page_no: pagesInfo.current + 1,
                            })
                          );
                        }}
                      />
                    </>
                  ) : null}
                </>
              ) : tab == tabs.Deals ? (
                <p className="text-[0.9rem] font-[600] self-center">
                  Deals have not been implemented yet. Stay tuned for future
                  updates.
                </p>
              ) : null}
            </div>
          </>
        </div>
      </div>
      <MFilters />
    </CContainer>
  );
}

function CContact({ contact }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-col items-start justify-start w-[100%] h-[12rem] p-[2%] my-[1rem] bg-white shadow-lg border-[#F5F5F5] border-solid border-[1px] cursor-pointer flex-shrink-0"
      onClick={() => {
        navigate(`/contact_info?id=${contact.id}`);
      }}
    >
      <div className="flex flex-row items-center justify-between w-[100%]">
        <div className="flex flex-row items-start justify-start">
          <p className="text-[1rem]">
            {`${contact.firstName ?? "N/A"} ${contact.lastName ?? "N/A"}`}
          </p>
        </div>
        <div
          className={`flex flex-row items-center justify-center rounded-[2rem] px-[1rem] py-[0.2rem] ml-[0.2rem] ${
            contact.type == contactTypes.client
              ? "bg-blue-400"
              : contact.type == contactTypes.lead
              ? "bg-green-400"
              : contact.type == contactTypes.acquired_lead
              ? "bg-yellow-400"
              : "bg-white"
          }`}
        >
          <p className="text-[0.9rem] font-[600] whitespace-nowrap">
            {t(contact.type)}
          </p>
        </div>
      </div>
      <p className="text-[0.90rem]">{`Email: ${contact.email ?? "N/A"}`}</p>
      <p className="text-[0.90rem]">{`Phone: ${contact.phone ?? "N/A"}`}</p>
      <div className="flex-grow" />
      <p className="text-[0.90rem]">{`${dateTimeFormatter.format(
        contact.createdAt
      )}`}</p>
    </div>
  );
}

const MFilters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const contactsFilter = useSelector((state) => state.crm.contactsFilter);

  const [type, setType] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [status, setStatus] = useState(null);
  const [isSalesRepresentativeArchived, setIsSalesRepresentativeArchived] =
    useState(null);

  useEffect(() => {
    setType(contactsFilter.type);
    setName(contactsFilter.name);
    setEmail(contactsFilter.email);
    setPhone(contactsFilter.phone);
    setStatus(contactsFilter.status);
    setIsSalesRepresentativeArchived(
      contactsFilter.isSalesRepresentativeArchived
    );
  }, [contactsFilter]);

  return (
    <dialog id="modal_contacts_filters" className="modal">
      <form
        method="dialog"
        className="modal-box bg-white min-h-[60dvh] min-w-[40dvw]"
      >
        <div className="flex flex-col items-start justify-center bg-white p-[2%] overflow-scroll h-[100%] w-[100%]">
          <div className="flex flex-row items-center justify-between w-[100%]">
            <CDropDown label={t("type")} value={type ? t(type) : null}>
              {Object.values(contactTypes).map((_) => (
                <CDropDownMenu
                  title={t(_)}
                  isSelected={type == _}
                  onClick={() => setType(_)}
                />
              ))}
            </CDropDown>
            <div className="w-[20px]" />
            <img
              src={CancelSVG}
              className="h-[30px] w-[30px] cursor-pointer"
              onClick={() => {
                setType(null);
              }}
            />
          </div>
          <div className="h-[20px]" />
          <div className="flex flex-row items-center justify-between w-[100%]">
            <CInput
              label={t("name")}
              type="text"
              className="w-[100%]"
              placeholder={t("enter_contact_name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="w-[20px]" />
            <img
              src={CancelSVG}
              className="h-[30px] w-[30px] cursor-pointer"
              onClick={() => {
                setName("");
              }}
            />
          </div>
          <div className="h-[20px]" />
          <div className="flex flex-row items-center justify-between w-[100%]">
            <CInput
              label={t("email")}
              type="text"
              className="w-[100%]"
              placeholder={t("enter_email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="w-[20px]" />
            <img
              src={CancelSVG}
              className="h-[30px] w-[30px] cursor-pointer"
              onClick={() => {
                setEmail("");
              }}
            />
          </div>
          <div className="h-[20px]" />
          <div className="flex flex-row items-center justify-between w-[100%]">
            <CInput
              label={t("phone")}
              type="text"
              className="w-[100%]"
              placeholder={t("enter_phone")}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <div className="w-[20px]" />
            <img
              src={CancelSVG}
              className="h-[30px] w-[30px] cursor-pointer"
              onClick={() => {
                setPhone("");
              }}
            />
          </div>
          <div className="h-[20px]" />
          <div className="flex flex-row items-center justify-between w-[100%]">
            <CDropDown
              label={t("archived")}
              value={isSalesRepresentativeArchived}
            >
              {(locale == locales.italian ? ["SÌ", "NO"] : ["Yes", "No"]).map(
                (_) => (
                  <CDropDownMenu
                    title={_}
                    isSelected={isSalesRepresentativeArchived == _}
                    onClick={() => setIsSalesRepresentativeArchived(_)}
                  />
                )
              )}
            </CDropDown>
            <div className="w-[20px]" />
            <img
              src={CancelSVG}
              className="h-[30px] w-[30px] cursor-pointer"
              onClick={() => {
                setIsSalesRepresentativeArchived(null);
              }}
            />
          </div>
          <div className="h-[20px]" />
          <CButton
            className="w-[100%] self-center"
            text={t("apply")}
            onClick={async () => {
              window.modal_contacts_filters.close();

              const _contactsFilter = ContactsFilter.parse(
                contactsFilter.toMap()
              );
              _contactsFilter.type = type;
              _contactsFilter.name = name;
              _contactsFilter.email = email;
              _contactsFilter.phone = phone;
              _contactsFilter.status = status;
              _contactsFilter.isSalesRepresentativeArchived =
                isSalesRepresentativeArchived;

              await dispatch(setContactsFilter(_contactsFilter));
              await dispatch(
                readContacts({
                  page_no: 1,
                  applyPagination: false,
                })
              );
            }}
          />
          <div className="h-[20px]" />
        </div>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};

export default SCRM;
