import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";
import { ContactsFilter, PagesInfo } from "../utils/models";
import {
  dateValidator,
  nullValidator,
  selectValidator,
  validate,
} from "../utils/validators";
import { contactTypes } from "../utils/constants";
import { stringToBoolean } from "../utils/misc";

const initialState = {
  isLoading: false,
  contacts: [],
  contactsPagesInfo: PagesInfo.parse({
    current: 1,
  }),
  contactsFilter: ContactsFilter.parse({
    isSalesRepresentativeArchived: false,
  }),
  contact: null,
  activitiesPagesInfo: PagesInfo.parse({
    current: 1,
  }),

  activities: [],
  leadProjectInfo: null,
  leadFinancialDocuments: [],
};

const readContacts = createAsyncThunk(
  "crm/readContacts",
  async (args, { getState }) => {
    try {
      const state = getState();

      const {
        page_no = state.crm.contactsPagesInfo.current,
        applyFilter = true,
      } = args ?? {};

      const filter = state.crm.contactsFilter;

      let _body = {
        page_no,
      };

      if (applyFilter) {
        _body = {
          type: filter.type,
          name: filter.name,
          email: filter.email,
          phone: filter.phone,
          status: filter.status,
          acquired_at_start: filter.acquiredAtStart,
          acquired_at_end: filter.acquiredAtEnd,
          is_sales_representative_archived: stringToBoolean(
            filter.isSalesRepresentativeArchived
          ),
          ..._body,
        };
      }

      const [pagesInfo, contacts] = await AppNetworkRest.readContacts(_body);
      return [pagesInfo, contacts];
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const readContact = createAsyncThunk(
  "crm/readContact",
  async (args, { getState }) => {
    const { id } = args;

    try {
      const contact = await AppNetworkRest.readContact(args);
      return contact;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const updateContact = createAsyncThunk(
  "crm/updateContact",
  async (args, { getState }) => {
    try {
      const { status, payment_type, is_sales_representative_archived } = args;

      const str = await AppNetworkRest.updateContact(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
      return false;
    }
  }
);

const createActivity = createAsyncThunk(
  "crm/createActivity",
  async (args, { getState }) => {
    try {
      const { contact_id, type, date, note, is_call_report, attachment } = args;

      const valError = validate([
        () => selectValidator("Type", type),
        () =>
          dateValidator("Date", date, {
            allowNull: true,
          }),
        () => nullValidator("Note", note),
      ]);
      if (valError) {
        toast.error(valError);
        return false;
      }

      const res = await AppNetworkRest.createCRMActivity(args);

      if (res) {
        toast.success(res);
      }

      return res;
    } catch (e) {
      console.log(e, "e");
      return null;
    }
  }
);

const readActivities = createAsyncThunk(
  "crm/readActivities",
  async (args, { getState }) => {
    try {
      const state = getState();

      const {
        page_no = state.crm.activitiesPagesInfo.current,
        contact_id,
        crm_deal_id,
      } = args ?? {};

      let _body = {
        page_no,
        contact_id,
        crm_deal_id,
      };

      const [pagesInfo, crmActivities] = await AppNetworkRest.readCRMActivities(
        _body
      );
      return [pagesInfo, crmActivities];
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const createLeadProjectInfo = createAsyncThunk(
  "crm/createLeadProjectInfo",
  async (args, { getState }) => {
    try {
      const { lead_id, description, power_kit, installation_kit } = args;

      const res = await AppNetworkRest.createLeadProjectInfo(args);

      if (res) {
        toast.success(res);
      }

      return res;
    } catch (e) {
      console.log(e, "e");
      return null;
    }
  }
);

const readLeadProjectInfo = createAsyncThunk(
  "crm/readLeadProjectInfo",
  async (args, { getState }) => {
    try {
      const { lead_id } = args ?? {};
      const leadProjectInfo = await AppNetworkRest.readLeadProjectInfo({
        lead_id,
      });
      return leadProjectInfo;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const createLeadFinancialDocument = createAsyncThunk(
  "crm/createLeadFinancialDocument",
  async (args, { getState }) => {
    try {
      const { lead_id, type, note, attachment } = args;

      const res = await AppNetworkRest.createLeadFinancialDocument(args);

      if (res) {
        toast.success(res);
      }

      return res;
    } catch (e) {
      console.log(e, "e");
      return null;
    }
  }
);

const readLeadFinancialDocuments = createAsyncThunk(
  "crm/readLeadFinancialDocuments",
  async (args, { getState }) => {
    try {
      const { lead_id } = args ?? {};
      const leadFinancialDocuments =
        await AppNetworkRest.readLeadFinancialDocuments({
          lead_id,
        });
      return leadFinancialDocuments;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const updateLeadFinancialDocument = createAsyncThunk(
  "crm/updateLeadFinancialDocument",
  async (args, { getState }) => {
    try {
      const { lead_id, type, note, attachment } = args;

      const res = await AppNetworkRest.updateLeadFinancialDocument(args);

      if (res) {
        toast.success(res);
      }

      return res;
    } catch (e) {
      console.log(e, "e");
      return null;
    }
  }
);

export const crmSlice = createSlice({
  name: "crm",
  initialState,
  reducers: {
    clearActivities: (state, action) => {
      state.activitiesPagesInfo = PagesInfo.parse({
        current: 1,
      });
      state.activities = [];
    },
    setContact: (state, action) => {
      state.contact = action.contact;
    },
    clearLeadProjectInfo: (state, action) => {
      state.leadProjectInfo = null;
    },
    clearLeadFinancialDocuments: (state, action) => {
      state.leadFinancialDocuments = [];
    },
    setContactsFilter: (state, action) => {
      state.contactsFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readContacts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readContacts.fulfilled, (state, action) => {
      const { applyPagination = true } = action.meta.arg ?? {};

      state.isLoading = false;

      if (action.payload) {
        const [contactsPagesInfo, contacts] = action.payload;

        state.contactsPagesInfo = contactsPagesInfo;

        if (!applyPagination) {
          state.contacts = contacts;
        } else {
          const array = [...state.contacts, ...contacts];
          state.contacts = array.filter(
            (v, i, self) => i == self.findIndex((_) => _.id == v.id)
          );
        }
      }
    });
    builder.addCase(readContact.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readContact.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.contact = action.payload;
      }
    });
    builder.addCase(readActivities.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readActivities.fulfilled, (state, action) => {
      const contactID = action.meta.arg.contact_id;

      if (action.payload) {
        const [activitiesPagesInfo, activities] = action.payload;

        state.activitiesPagesInfo = activitiesPagesInfo;

        if (state.contact?.id == contactID) {
          const array = [...activities, ...state.activities];
          state.activities = array.filter(
            (v, i, self) => i == self.findIndex((_) => _.id == v.id)
          );
        } else {
          state.activities = activities;
        }
      }

      state.isLoading = false;
    });
    builder.addCase(readLeadProjectInfo.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readLeadProjectInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.leadProjectInfo = action.payload;
      }
    });
    builder.addCase(readLeadFinancialDocuments.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readLeadFinancialDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.leadFinancialDocuments = action.payload;
      }
    });
  },
});

export const {
  setContact,
  clearActivities,
  clearLeadProjectInfo,
  clearLeadFinancialDocuments,
  setContactsFilter,
} = crmSlice.actions;

export {
  readContacts,
  readContact,
  updateContact,
  createActivity,
  readActivities,
  createLeadProjectInfo,
  readLeadProjectInfo,
  createLeadFinancialDocument,
  readLeadFinancialDocuments,
  updateLeadFinancialDocument,
};

export default crmSlice.reducer;
