import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth";
import controlBarReducer from "./control_bar";
import crmReducer from "./crm";
import communicationReducer from "./communication";
import filesReducer from "./files";
import notificationsReducer from "./notifications";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    controlBar: controlBarReducer,
    crm: crmReducer,
    communication: communicationReducer,
    files: filesReducer,
    notifications: notificationsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
