import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setSelectedRouteTitle } from "../controllers/control_bar";
import { readContact } from "../controllers/crm";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";

import PersonSVG from "../assets/images/person.svg";

import { convertToMinutesAndSeconds } from "../utils/misc";
import localforage from "localforage";

const timers = [];

function SCall() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const contactID = searchParams.get("contact_id");

  const contact = useSelector((state) => state.crm.contact);

  const [isRecording, setIsRecording] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const audioChunksRef = useRef([]);

  useEffect(() => {
    _init();
  }, []);

  useEffect(() => {
    return () => clearTimer();
  }, []);

  const _init = async () => {
    if (!contactID) {
      navigate(-1);
      return;
    }

    dispatch(setSelectedRouteTitle("crm"));
    dispatch(
      readContact({
        id: contactID,
      })
    );
  };

  const initTimer = () => {
    const timer = setInterval(() => {
      setSeconds((s) => s + 1);
    }, 1000);
    timers.push(timer);
  };

  const clearTimer = () => {
    for (let i = 0; i < timers.length; i++) {
      clearInterval(timers[0]);
    }
  };

  const makeCallAndRecord = async () => {
    try {
      if (isRecording) {
        return;
      }

      initTimer();

      window.open(`tel:${contact.phone}`, "_self");

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      streamRef.current = stream;

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (err) {
      console.error("Error accessing audio devices:", err);
    }
  };

  const endCallRecording = () => {
    if (!isRecording) {
      return;
    }

    clearTimer();

    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      streamRef.current.getTracks().forEach((_) => _.stop());
      mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        const appUser = await localforage.getItem("appUser");
        const file = new File(
          [blob],
          `Call - Sales rep - ${appUser.id} - contact - ${
            contact.id
          }_${Date.now()}.wav`,
          { type: "audio/wav" }
        );

        navigate(`/call_outcome?contact_id=${contactID}`, {
          state: { file: file },
        });
      };
      setIsRecording(false);
    }
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%]">
          <div className="flex flex-row items-center justify-between w-[100%]">
            <p className="text-[20px] font-[600]">{t("call")}</p>
          </div>
          <div className="h-[20px]" />
          {!contact ? (
            <CLoader />
          ) : (
            <div className="flex flex-col items-center justify-center w-[100%] h-[100%] p-[2%] overflow-scroll">
              <img
                className="w-[100%] h-[30%] object-contain"
                src={PersonSVG}
              />
              <div className="flex flex-row items-start justify-start">
                <p className="text-[1rem]">
                  {`${contact.firstName ?? "N/A"} ${contact.lastName ?? "N/A"}`}
                </p>
              </div>
              <p className="text-[0.90rem]">{`${contact.phone ?? "N/A"}`}</p>
              <div className="flex-grow" />
              <p className="text-[0.90rem]">
                {convertToMinutesAndSeconds(seconds)}
              </p>
              <div className="flex-grow" />
              {contact.phone ? (
                <CButton
                  text={isRecording ? t("end") : t("call")}
                  onClick={() => {
                    if (isRecording) {
                      endCallRecording();
                    } else {
                      makeCallAndRecord();
                    }
                  }}
                  className="px-[5rem]"
                />
              ) : null}
              <div className="mt-[2rem]" />
            </div>
          )}
        </div>
      </div>
    </CContainer>
  );
}

export default SCall;
