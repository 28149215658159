import { useTranslation } from "react-i18next";
import CancelSVG from "../assets/images/cancel.svg";

function CFilterBadge({ text, className, onCancel }) {
  return (
    <div
      className={`flex flex-row items-center justify-center bg-[#00A96E] rounded-[2rem] px-[2rem] py-[0.2rem] ml-[0.2rem] ${className}`}
    >
      <div className="flex flex-row items-center justify-center">
        <img className="cursor-pointer" src={CancelSVG} onClick={onCancel} />
        <div className="mr-[0.2rem]" />
        <p className="text-[0.9rem] font-[600] whitespace-nowrap">{text}</p>
      </div>
    </div>
  );
}

function CFilterBadges({ filter, filterMap = {}, filterVMap = {}, onCancel }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center justify-center ml-[2rem]">
      {Object.keys(filter).map((_) =>
        filter[_] && filterMap[_] ? (
          <CFilterBadge
            text={`${filterMap[_]}: ${filterVMap[_] ?? filter[_]}`}
            onCancel={() => onCancel(_)}
          />
        ) : null
      )}
    </div>
  );
}

export default CFilterBadges;
