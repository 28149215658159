import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setSelectedRouteTitle } from "../controllers/control_bar";
import { readContact, updateContact } from "../controllers/crm";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";

import { leadPaymentTypes } from "../utils/constants";
import toast from "react-hot-toast";

function SLeadPaymentType() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const contactID = searchParams.get("contact_id");

  const contact = useSelector((state) => state.crm.contact);

  const [paymentType, setPaymentType] = useState(null);

  useEffect(() => {
    _init();
  }, []);

  useEffect(() => {
    setPaymentType(contact?.paymentType);
  }, [contact]);

  const _init = async () => {
    if (!contactID) {
      navigate(-1);
      return;
    }

    dispatch(setSelectedRouteTitle("crm"));
    dispatch(
      readContact({
        id: contactID,
      })
    );
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%]">
          <div className="flex flex-row items-center justify-between w-[100%]">
            <p className="text-[20px] font-[600]">{t("lead_payment_type")}</p>
          </div>
          <div className="h-[20px]" />
          {!contact ? (
            <CLoader />
          ) : (
            <div className="flex flex-col self-center items-center justify-start w-[100%] h-[100%]">
              <div className="flex flex-col items-start justify-start w-[100%]">
                <p className="text-[1rem]">
                  {`Name: ${contact.firstName ?? "N/A"} ${
                    contact.lastName ?? "N/A"
                  }`}
                </p>
                <p className="text-[0.90rem]">{`Email: ${
                  contact.email ?? "N/A"
                }`}</p>
              </div>
              <div className="my-[2%]" />
              <CPaymentType
                paymentType={leadPaymentTypes.bank_transfer}
                isSelected={paymentType == leadPaymentTypes.bank_transfer}
                onClick={(_) => {
                  setPaymentType(_);
                }}
              />
              <CPaymentType
                paymentType={leadPaymentTypes.financial_company}
                isSelected={paymentType == leadPaymentTypes.financial_company}
                onClick={(_) => {
                  setPaymentType(_);
                }}
              />
              <CPaymentType
                paymentType={leadPaymentTypes.financial_company_with_guarantor}
                isSelected={
                  paymentType ==
                  leadPaymentTypes.financial_company_with_guarantor
                }
                onClick={(_) => {
                  setPaymentType(_);
                }}
              />
              <div className="flex-grow" />
              <p className="text-[0.9rem] font-[500]">
                * Once a payment type is selected for a lead it can't be changed
                later on. Choose carefully.
              </p>
              <div className="my-[0.5rem]" />
              <CButton
                text="Continue"
                onClick={async () => {
                  if (!paymentType) {
                    toast.error("Payment type must be selected.");
                    return;
                  }

                  const res = await dispatch(
                    updateContact({
                      id: contactID,
                      payment_type: paymentType,
                    })
                  );

                  if (res.payload) {
                    navigate(-1);
                  }
                }}
              />
              <div className="my-[2%]" />
            </div>
          )}
        </div>
      </div>
    </CContainer>
  );
}

function CPaymentType({ paymentType, isSelected, onClick }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center justify-start w-[100%] p-[2.5%]">
      <input
        type="checkbox"
        checked={isSelected}
        className="checkbox checkbox-lg"
        onClick={() => {
          onClick(paymentType);
        }}
      />
      <div className="mx-[1rem]" />
      <p className="text-[1rem] font-[600]">{t(paymentType)}</p>
    </div>
  );
}

export default SLeadPaymentType;
