import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CContainer from "../components/container";
import CInput from "../components/input";
import CButton from "../components/button";
import {
  clearLeadProjectInfo,
  createLeadProjectInfo,
  readLeadProjectInfo,
} from "../controllers/crm";

function SLeadProjectInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const leadID = searchParams.get("lead_id");

  const isLoading = useSelector((state) => state.crm.isLoading);
  const leadProjectInfo = useSelector((state) => state.crm.leadProjectInfo);

  const [description, setDescription] = useState(null);
  const [powerKit, setPowerKit] = useState(null);
  const [installationKit, setInstallationKit] = useState(null);

  useEffect(() => {
    _init();

    return () => {
      dispatch(clearLeadProjectInfo());
    };
  }, []);

  useEffect(() => {
    if (leadProjectInfo) {
      setDescription(leadProjectInfo.description);
      setPowerKit(leadProjectInfo.powerKit);
      setInstallationKit(leadProjectInfo.installationKit);
    }
  }, [leadProjectInfo]);

  const _init = async () => {
    if (!leadID) {
      navigate(-1);
      return;
    }

    dispatch(
      readLeadProjectInfo({
        lead_id: leadID,
      })
    );
  };

  const onClick = async () => {
    await dispatch(
      createLeadProjectInfo({
        lead_id: leadID,
        description: description,
        power_kit: powerKit,
        installation_kit: installationKit,
      })
    );
    await dispatch(
      readLeadProjectInfo({
        lead_id: leadID,
      })
    );
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%]">
          <div className="flex flex-col items-center justify-between h-[100%] w-[100%]">
            <p className="text-[20px] font-[600]">{t("lead_project_info")}</p>
            <div className="h-[2%]" />
            <div className="flex flex-col items-start justify-start w-[100%] h-[100%] p-[2%] overflow-scroll">
              <div className="flex flex-col justify-start items-start w-[100%]">
                <p className="text-[12px]">{t("description")}</p>
                <textarea
                  className="bg-[#F3F6FF] p-[10px] w-[100%] text-[14px] outline-0 rounded-[20px] h-[25dvh] resize-none"
                  placeholder={t("enter_description")}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                />
              </div>
              <div className="h-[2%]" />
              <CInput
                label={t("power_kit")}
                type="text"
                className="w-[100%]"
                placeholder={t("enter_power_kit")}
                value={powerKit}
                onChange={(e) => setPowerKit(e.target.value)}
              />
              <div className="h-[2%]" />
              <div className="flex flex-col justify-start items-start w-[100%]">
                <p className="text-[12px]">{t("installation_kit")}</p>
                <textarea
                  className="bg-[#F3F6FF] p-[10px] w-[100%] text-[14px] outline-0 rounded-[20px] h-[15dvh] resize-none"
                  placeholder={t("enter_installation_kit")}
                  value={installationKit}
                  onChange={(e) => setInstallationKit(e.target.value)}
                  type="text"
                />
              </div>
              <div className="flex-grow" />
              <CButton
                className="my-[14px] w-[100%]"
                text={t("continue")}
                onClick={onClick}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </CContainer>
  );
}

export default SLeadProjectInfo;
