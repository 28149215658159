import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedRouteTitle: null,
};

export const controlBarSlice = createSlice({
  name: "camera",
  initialState,
  reducers: {
    setSelectedRouteTitle: (state, action) => {
      state.selectedRouteTitle = action.payload;
    },
  },
});

export const { setSelectedRouteTitle } = controlBarSlice.actions;

export default controlBarSlice.reducer;
