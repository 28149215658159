import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CContainer from "../components/container";

import { setSelectedRouteTitle } from "../controllers/control_bar";
import { logout } from "../controllers/auth";

import SNotificationsSVG from "../assets/images/s_notifications.svg";
import SPasswordResetSVG from "../assets/images/s_password_reset.svg";
import SAboutSVG from "../assets/images/s_about.svg";
import SLogoutSVG from "../assets/images/s_logout.svg";

function SSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteTitle("settings"));
  };

  return (
    <CContainer>
      <div className="flex flex-col items-start justify-start sm:h-[80%] sm:w-[80%] h-[100%] w-[100%] bg-container-background sm:rounded-[10px] rounded-none">
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[2%] overflow-scroll">
          <p className="text-[20px] font-[500] self-center">{t("settings")}</p>
          <div className="h-[20px]" />
          <CSListTile
            icon={SNotificationsSVG}
            title={t("notifications")}
            onClick={() => {
              navigate("/notifications");
            }}
          />
          {/* <CSListTile
            icon={SPasswordResetSVG}
            title={t("reset_password")}
            onClick={() => {
              navigate("/reset_password");
            }}
          /> */}
          <CSListTile
            icon={SAboutSVG}
            title={t("about")}
            onClick={() => {
              navigate("/about");
            }}
          />
          <CSListTile
            icon={SLogoutSVG}
            title={t("logout")}
            onClick={() => dispatch(logout())}
          />
        </div>
      </div>
    </CContainer>
  );
}

function CSListTile({ icon, title, onClick }) {
  return (
    <div
      className="flex flex-row items-center justify-start sm:my-[0.5%] my-[1%] p-[1%] w-[100%] cursor-pointer"
      onClick={onClick}
    >
      <img src={icon} className="h-[20px] w-[20px]" />
      <div className="w-[10px]" />
      <p className="text-[16px] font-[600]">{title}</p>
    </div>
  );
}

export default SSettings;
