import { filesURLSplitSequence, locales } from "./constants";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function getFileExt(filename) {
  return filename.split(".").pop();
}

function getFileNameAndExt(filename) {
  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return [filename, ""];
  }
  const name = filename.slice(0, lastDotIndex);
  const extension = filename.slice(lastDotIndex);
  return [name, extension];
}

function mergeObjects(obj1, obj2) {
  const merged = {};

  obj1 = obj1 ?? {};
  obj2 = obj2 ?? {};

  const obj1Keys = Object.keys(obj1);
  for (let i = 0; i < obj1Keys.length; i++) {
    const key = obj1Keys[i];
    merged[key] = obj1[key];
  }

  const obj2Keys = Object.keys(obj2);
  for (let i = 0; i < obj2Keys.length; i++) {
    const key = obj2Keys[i];
    if (!merged[key]) {
      merged[key] = obj2[key];
    }
  }

  return merged;
}

function arrayToObject(arr) {
  const obj = {};
  arr.forEach((v, i) => {
    obj[i] = v;
  });
  return obj;
}

function capitalizeString(str) {
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function downloadBlob({ blob, name }) {
  const href = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

function convertToMinutesAndSeconds(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
}

const locale = localStorage.getItem("locale") ?? locales.italian;

function booleanToString(bool) {
  let str = null;

  if (bool == true) {
    if (locale == locales.italian) {
      str = "SÌ";
    } else {
      str = "Yes";
    }
  } else if (bool == false) {
    if (locale == locales.italian) {
      str = "NO";
    } else {
      str = "No";
    }
  }

  return str;
}

function stringToBoolean(string) {
  let bool = null;

  if (["SÌ", "Yes"].includes(string)) {
    bool = true;
  } else if (["NO", "No"].includes(string)) {
    bool = false;
  }

  return bool;
}

export {
  dataURLtoFile,
  getFileExt,
  getFileNameAndExt,
  mergeObjects,
  arrayToObject,
  capitalizeString,
  downloadBlob,
  convertToMinutesAndSeconds,
  booleanToString,
  stringToBoolean,
};
